import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useApi from '../../api';
import { toast } from 'react-toastify';
import toastConfig from '../../config/Toast';
import 'react-toastify/dist/ReactToastify.css';
import { conLog } from '../../utils/conLog';
import FilesList from './FilesList';
import BeatLoader from '../../ui/BeatLoader';

export default function Files({ agentId }: { agentId: string }) {
    let [isOpen, setIsOpen] = useState(false);
    const [files, setFiles] = useState([]);
    const { get, post, remove } = useApi();
    const [loading, setLoading] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    const [showFiles, setShowFiles] = useState(false);
    const [filesSelected, setFilesSelected] = useState(false);
    const navigate = useNavigate();

    function closeModal() {
        setIsOpen(false);
    }

    useEffect(() => {
        if (agentId) {
            get(`/files/?agent_id=${agentId}`).then((res) => {
                setFiles(res.data);
                conLog('Files:', res.data);
                setLoading(false);
            }).catch((error) => {
                console.error('Error fetching files:', error);
            });
        }
    }, [agentId, showFiles]);

    function handleFileUpload(event: any) {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
        }
    }

    function handleIndexFile() {
        if (selectedFile) {
            const formData = new FormData();
            formData.append('files', selectedFile);
            formData.append('type', 'file');

            setLoading(true);

            post(`/documents/file?agent_id=${agentId}`, formData)
                .then(response => {
                    conLog('File processing started:', response.data);
                    toast.success('File processing started', toastConfig);
                    setLoading(false);
                    closeModal();
                    setSelectedFile(null);
                    
                    get(`/files/?agent_id=${agentId}`).then((res) => {
                        setFiles(res.data);
                    }).catch((error) => {
                        console.error('Error fetching files:', error);
                    });
                })
                .catch(error => {
                    console.error('Error starting file processing:', error);
                    toast.error('Error starting file processing', toastConfig);
                    setLoading(false);
                });
        }
    }

    function deleteFileAssociation(fileId: string) {
        remove(`/agents/${agentId}/files/${fileId}`)
            .then((response) => {
                toast.success('File association deleted', toastConfig);
                setFiles((prevFiles) => prevFiles.filter((file: any) => file.id !== fileId));
            })
            .catch((error) => {
                console.error('Error deleting file association:', error);
                toast.error('Error deleting file association', toastConfig);
            });
    }

    return (
        <>
            <div className="flex flex-row w-full max-w-full pt-2">
                <div className="flex flex-col pr-6 pl-2 w-1/2 max-w-1/2">
                    <div className="p-4 border rounded-lg mt-4 bg-white dark:bg-slate-700 dark:border-slate-600">
                        <input
                            type="file"
                            accept=".docx, .pdf"
                            onChange={handleFileUpload}
                            className="block w-full text-sm text-slate-500
                                                        file:mr-4 file:py-2 file:px-4
                                                        file:rounded file:border-0
                                                        file:text-sm file:font-semibold
                                                        file:bg-slate-100 file:text-slate-700
                                                        hover:file:bg-slate-200 dark:file:bg-slate-600 dark:file:text-slate-50 dark:hover:file:bg-slate-600/50 transition duration-200 dark:text-slate-200"
                        />
                        {selectedFile && (
                            <div className="flex justify-center w-full mt-4">
                                <button
                                    type="button"
                                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-slate-100 px-4 py-2 text-sm font-medium text-slate-900 hover:bg-slate-200 transition duration-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 dark:bg-slate-600 dark:text-slate-50 dark:hover:bg-slate-600/50"
                                    onClick={handleIndexFile}
                                >
                                    Train Agent
                                </button>
                            </div>
                        )}
                    </div>
                </div>
                <div className="mt-4 pr-2 pl-6 border-l grow dark:border-slate-700 w-1/2 max-w-1/2">
                    <div className="block text-md font-semibold leading-6 text-slate-800/60 px-2 py-1 border bg-slate-50 rounded-md text-center mb-2 dark:bg-slate-700 dark:text-slate-200 dark:border-slate-600">
                        Trained Files
                    </div>
                    <div className="pt-2">
                        <div onClick={() => setShowFiles(true)} className="p-4 group flex text-slate-500 rounded-lg hover:cursor-pointer items-center border border-dashed justify-center dark:text-slate-200 text-sm font-medium dark:bg-slate-700/40 dark:border-slate-700">
                            <div className="group-hover:scale-105 transition duration-200">
                                Select Existing
                            </div>
                        </div>
                    </div>
                    {files.length > 0 && (
                    <div className="border-b border-slate-200 mt-4 mb-2 dark:border-slate-700">

                    </div>
                    )}
                    <div className="flex flex-col pt-2 space-y-2">
                        {files.map((file: any) => (
                            <div className="flex flex-col w-full items-center p-2 rounded-md border bg-white text-sm font-medium text-slate-800 shadow-sm transition duration-200 dark:bg-slate-700 dark:border-none dark:hover:bg-slate-700/50">
                                <div onClick={() => deleteFileAssociation(file.id)} className="flex justify-end w-full">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 stroke-slate-400 flex-shrink-0 hover:cursor-pointer hover:stroke-slate-600 transition duration-200 dark:stroke-slate-300">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                                    </svg>
                                </div>
                                <div className="flex flex-row justify-between w-full px-4 pb-5 pt-1">
                                    <div className="flex flex-row items-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="mr-3 w-6 h-6 stroke-slate-400 flex-shrink-0 dark:stroke-slate-300">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
                                        </svg>
                                        <span key={file.id} className={`mr-6 dark:text-slate-200 break-all`}>
                                            {file.name.length > 50 ? file.name.substring(0, 50) + '...' : file.name}
                                        </span>
                                    </div>
                                    <div className="flex flex-row bg-slate-100 text-sm text-slate-500 text-xs rounded-md px-2 py-1 items-center justify-end dark:bg-slate-900/10 dark:border dark:border-slate-800">
                                        <div className="dark:text-slate-200">
                                            {`${file.characters} characters`}
                                        </div>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="ml-2 w-4 h-4 stroke-green-500 flex-shrink-0">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        ))}
                        {loading && (
                            <BeatLoader />
                        )}
                        {showFiles && (
                            <FilesList
                                agentId={agentId}
                                setShowFiles={setShowFiles}
                                setFilesSelected={setFilesSelected}
                            />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}