import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState, useEffect } from 'react'
import useApi from '../api';
import Spinner from '../documentation/Spinner';
import useAnalytics from '../analytics/analytics';

const plans = [
    {
        id: 'price_1PLtPgRrIYh07XPi9QhWz1Ad',
        name: 'Standard',
        price: '$49/month',
        features: [
            "3 Agents and 3 Seats",
            "3,000 Agent Responses",
            "10M Character Knowledge Base",
            "Session Transcript",
            "Lead Capture",
            "Unlimited Website Embeds",
            "Google Drive Integration"
        ]
    },
    {
        id: 'price_1PLtQ1RrIYh07XPiJdWuRYIU',
        name: 'Pro',
        price: '$99/month',
        features: [
            "Everything in Standard Plan",
            "6 Agents and 6 Seats",
            "6,500 Agent Responses",
            "20M Character Knowledge Base",
            "Advanced Session Analytics",
            "HubSpot Integration",
            "Calendar Integrations",
            "Lead Qualification",
            "Email Campaign Integration"
        ]
    },
    {
        id: 'price_1PninlRrIYh07XPiqSL8htDw',
        name: 'Business',
        price: '$399/month',
        features: [
            "Everything in Pro Plan",
            "20 Agents and 20 Seats",
            "30,000 Agent Responses",
            "80M Character Knowledge Base",
            "AI Session Analysis",
            "AI Lead Scoring",
            "Webhooks",
            "REST API (coming soon)",
            "Dedicated Resource",
            "Priority Support"
        ]
    }
];

declare global {
    interface Window {
        rewardful: any;
        Rewardful: any;
    }
}

export default function Upgrade({ showModal, setShowModal, sessionId, trialExpired }: { showModal: any, setShowModal: any, sessionId: string | null, trialExpired: boolean }) {
    const { post } = useApi();
    const [selectedPlan, setSelectedPlan] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const { captureEvent } = useAnalytics();
    const [referralId, setReferralId] = useState<string | null>(null);

    useEffect(() => {
        if (window.rewardful) {
            window.rewardful('ready', function () {
                if (window.Rewardful && window.Rewardful.referral) {
                    setReferralId(window.Rewardful.referral);
                } else {
                }
            });
        }
    }, []);

    const createCheckoutSession = async (planId: string) => {
        setLoading(true);

        const requestBody = referralId !== null ? { referral_id: referralId } : {};

        const response = await post(`/create-checkout-session/${planId}`, requestBody);
        setLoading(false);
        if (response.data.checkout_url) {
            window.location.href = response.data.checkout_url;
        } else {
            console.error("Failed to create checkout session");
        }
    };

    const handlePlanSelect = (planId: string) => {
        setSelectedPlan(planId);
        createCheckoutSession(planId);
        captureEvent('plan_selected', {
            plan_id: planId,
            plan_name: plans.find(plan => plan.id === planId)?.name
        });
    };

    const closeAndClearSession = () => {
        if (!trialExpired) {
            setShowModal(false);
        }
        window.history.pushState({}, document.title, window.location.pathname);
        captureEvent('plan_window_closed', {});
    };

    return (
        <Transition appear show={showModal} as={Fragment}>
            <Dialog as="div" className="relative z-10 h-full" onClose={() => closeAndClearSession()}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/25" />
                </Transition.Child>
                <div className="fixed inset-0 h-full">
                    <div className="flex min-h-full h-full items-center justify-center p-8 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full flex h-5/6 max-w-5xl transform rounded-2xl bg-white text-left align-middle shadow-xl transition-all dark:bg-slate-900 dark:border dark:border-slate-800">
                                {!sessionId ? (
                                    <div className="w-full h-full max-w-6xl p-8">
                                        <div className="w-full flex flex-row justify-center">
                                            <h1 className="text-2xl font-semibold dark:text-slate-50">Select a Plan</h1>
                                        </div>
                                        <div className="w-full flex flex-row justify-center space-x-4 mt-8">
                                            {plans.map(plan => (
                                                <div key={plan.id} className="flex flex-col justify-between bg-white shadow-sm border border-slate-200 rounded-lg dark:bg-slate-800 dark:border-slate-700">
                                                    <div className="s">
                                                        <div className="flex flex-col bg-slate-50 rounded-t-lg justify-between px-4 py-2 transition duration-200 dark:bg-slate-950">
                                                            <h2 className="text-lg font-light dark:text-slate-200">{plan.name}</h2>
                                                            <div className="text-2xl font-bold dark:text-slate-50 mt-2">{plan.price}</div>
                                                        </div>
                                                        <div className="mt-2 px-4 py-2 text-left dark:text-slate-400 space-y-3">
                                                            {plan.features.map((feature, index) => (
                                                                <div className="flex flex-row">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-5 w-5 fill-blue-800 stroke-white dark:stroke-none">
                                                                    <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12Zm13.36-1.814a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z" clipRule="evenodd" />
                                                                    </svg>
                                                                    <div key={index} className="ml-2 text-sm dark:text-slate-200">
                                                                        {feature}
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                    <div onClick={() => handlePlanSelect(plan.id)} className="flex justify-center font-semibold py-2 px-4 m-4 rounded-lg bg-slate-100 text-slate-700 border border-slate-200 hover:cursor-pointer hover:bg-slate-200 dark:bg-slate-900 dark:border-slate-700 dark:text-slate-100 dark:hover:bg-slate-800 transition duration-400">
                                                        Upgrade
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        {loading && <Spinner />}
                                    </div>
                                ) : (
                                    <div className="w-full h-full p-8 items-center">
                                        <h1 className="text-4xl font-semibold mb-4 dark:text-slate-200 w-full text-center">Thank you for upgrading!</h1>
                                    </div>
                                )}
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
}