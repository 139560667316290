import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useApi from '../api';
import UserCircle from '../user/UserCircle';
import { toast } from 'react-toastify';

const WeeklyLeads = () => {
    const { get } = useApi();
    const navigate = useNavigate();
    const [leads, setLeads] = useState<any[] | undefined>(undefined);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        fetchLeads();
    }, []);

    const fetchLeads = async () => {
        setLoading(true);
        try {
            const response = await get('/leads/?this_week=true');
            setLeads(response.data);
        } catch (error) {
            console.error('Error fetching leads:', error);
            toast.error('Error fetching leads');
        } finally {
            setLoading(false);
        }
    };

    const LeadsLoadingSkeleton = () => (
        <div className="flex flex-col w-full ml-4 mt-6 bg-white p-4 rounded-xl max-w-2xl shadow-lg dark:bg-slate-700">
            <div className="h-10 bg-slate-50 border border-slate-200 rounded-lg dark:bg-slate-600 dark:border-none mb-4"></div>
            <div className="animate-pulse space-y-4">
                {[1].map((i) => (
                    <div key={i} className="flex justify-between items-center bg-white rounded-lg border border-slate-200 p-4 dark:bg-slate-600 dark:border-slate-600">
                        <div className="flex space-x-4 items-center w-full">
                            <div className="flex-1">
                                <div className="h-5 bg-slate-200 rounded w-1/3 mb-2 dark:bg-slate-500"></div>
                                <div className="h-4 bg-slate-200 rounded w-1/2 dark:bg-slate-500"></div>
                            </div>
                            <div className="h-8 w-8 bg-slate-200 rounded-full dark:bg-slate-500"></div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );

    if (loading) {
        return <LeadsLoadingSkeleton />;
    }

    if (!leads) {
        return null;
    }

    return (
        <div className="flex flex-col w-full ml-4 mt-6 bg-white p-4 rounded-xl max-w-2xl shadow-lg dark:bg-slate-700">
            <h1 className="flex justify-between rounded-lg bg-slate-50 border border-slate-200 px-4 py-2 text-left text-xl font-medium text-slate-900 focus:outline-none dark:bg-slate-600 dark:text-slate-200 dark:border-none">
                {leads.length} New Leads This Week!
            </h1>
            <div className="flex flex-col overflow-x-auto max-h-72 mt-1 scrollbar-hidden space-y-2">
                {leads.map((lead: any) => (
                    <div className="p-1 mt-4" key={lead.id}>
                        <div 
                            onClick={() => navigate(`/leads/${lead.id}`)} 
                            className="flex flex-row justify-between bg-white rounded-lg border border-slate-200 pl-4 pr-1 py-2 hover:bg-slate-50 hover:cursor-pointer dark:bg-slate-600 dark:hover:bg-slate-600/50 dark:border-slate-600 transition duration-200"
                        >
                            <div className="flex flex-col space-y-1">
                                <h2 className="text-slate-900 font-medium text-lg dark:text-slate-200">
                                    {lead.first_name} {lead.last_name}
                                </h2>
                                <p className="text-slate-600 text-sm dark:text-slate-300">
                                    {lead.email}
                                </p>
                            </div>
                            <div className="flex flex-row space-x-2 items-center">
                                <UserCircle leadEmail={lead.email} />
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default WeeklyLeads;