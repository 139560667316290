import React, { useEffect, memo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

const TidyCalWidget = memo(({ 
    url,
    gettingFullResponse 
}: { 
    url: string,
    gettingFullResponse?: boolean 
}) => {
    const uuid = uuidv4();
    const uniqueId = `tidycal-widget-${uuid}`;

    return (
        <>
            <div className="p-4 m-4 rounded-lg border border-slate-200 dark:border-slate-700 bg-slate-50 dark:bg-slate-800/50">
                <div className="space-y-3">
                    <div className="flex items-center space-x-2">
                        <div className="h-5 w-5 text-green-500 dark:text-green-400">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                                <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clipRule="evenodd" />
                            </svg>
                        </div>
                        <span className="text-slate-900 dark:text-slate-100 font-medium">
                            TidyCal shown
                        </span>
                    </div>
                </div>
            </div>
            <div style={{ width: '100%', height: '1000px', border: 'none' }}>
                <iframe
                    src={url}
                    width="100%"
                    height="100%"
                    style={{ border: 'none' }}
                    title="TidyCal Widget"
                />
            </div>
        </>
    );
});

export default TidyCalWidget;
