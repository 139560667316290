import { Fragment, useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import SlackNotifications from './SlackNotifications';
import clsx from 'clsx';

interface SlackNotificationsModalProps {
    isOpen: boolean;
    closeModal: () => void;
    integration: any;
    value: any;
    onSave: (value: any) => void;
}

export default function SlackNotificationsModal({
    isOpen,
    closeModal,
    integration,
    value,
    onSave
}: SlackNotificationsModalProps) {
    const [localValue, setLocalValue] = useState(value);

    useEffect(() => {
        setLocalValue(value);
    }, [value, isOpen]);

    const handleSave = () => {
        onSave(localValue);
        closeModal();
    };

    const hasConfiguredChannels = localValue?.configs?.length > 0;

    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={closeModal}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all dark:bg-slate-800">
                                <Dialog.Title
                                    as="h3"
                                    className="text-lg font-medium leading-6 text-gray-900 dark:text-white"
                                >
                                    Configure Slack Notifications
                                </Dialog.Title>
                                <div className="mt-4">
                                    <SlackNotifications
                                        integration={integration}
                                        value={localValue}
                                        onChange={setLocalValue}
                                    />
                                </div>

                                <div className="mt-6 flex justify-end space-x-3">
                                    <button
                                        type="button"
                                        className="px-4 py-2 border border-slate-300 rounded-md dark:border-slate-600 dark:text-slate-300"
                                        onClick={closeModal}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        type="button"
                                        className={clsx(
                                            "inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white focus:outline-none",
                                            hasConfiguredChannels
                                                ? "bg-blue-600 hover:bg-blue-700"
                                                : "bg-blue-400 cursor-not-allowed"
                                        )}
                                        onClick={handleSave}
                                        disabled={!hasConfiguredChannels}
                                    >
                                        {hasConfiguredChannels ? 'Save' : 'Configure a channel to save'}
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
} 