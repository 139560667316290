import React, { useState, useMemo } from 'react';
import { MessageProps } from '../types';
import Markdown from 'react-markdown';
import remarkBreaks from 'remark-breaks';
import aimdocLogo from '../../logo_symbol.png';
import rehypeRaw from "rehype-raw";
import UserCircle from '../user/UserCircle';
import Calendly from '../agents/actions/Calendly';
import YouTube from '../agents/actions/YouTube';
import TidyCal from '../agents/actions/TidyCal';
import ChiliPiper from '../agents/actions/ChiliPiper';
import * as HeroIconSolid from '@heroicons/react/24/solid'
import { formatDate } from '../utils/dateFormatter'
import Form from '../agents/actions/Form';
import { memo } from 'react';

interface Component {
    type: string;
    [key: string]: any;
}

type ComponentMapping = {
    calendly: typeof Calendly;
    tidycal: typeof TidyCal;
    chili_piper: typeof ChiliPiper;
    youtube: typeof YouTube;
    form: typeof Form;
    form_field: typeof Form;
};

interface MemoizedComponentProps {
    component: Component;
    config: any;
    conversationId: string;
    gettingFullResponse: boolean;
}

const MemoizedComponent = memo(({ 
    component, 
    config, 
    conversationId,
    gettingFullResponse 
}: MemoizedComponentProps) => {

    const FallbackComponent = ({ component, content }: { component: any, content: any }) => {
        const displayContent = typeof component === 'string' ? component : JSON.stringify(component, null, 2);

        if (displayContent && displayContent.includes('\n')) {
            return (
                <div className={`font-light mt-6 mb-6 rounded-xl ${config ? "" : "bg-blue-100"} text-slate-800 p-4`} style={{ backgroundColor: `${config ? config.end_color : ""}` }}>
                    <code className="whitespace-pre-wrap break-words">{displayContent}</code>
                </div>
            );
        } else {
            return (
                <code className="border p-1 rounded-md bg-blue-100 text-slate-800 break-words">{displayContent}</code>
            );
        }
    };
    
    switch (component.type) {
        case 'calendly':
            return <Calendly 
                url={component.calendly_url} 
                key={component.calendly_url} 
                gettingFullResponse={gettingFullResponse || false}
            />;
        case 'form_field':
            return <Form />;
        case 'form':
            return <Form />;
        case 'tidycal':
            return <TidyCal 
                url={component.tidy_cal_url} 
                key={component.tidy_cal_url} 
                gettingFullResponse={gettingFullResponse || false}
            />;
        case 'chili_piper':
            return <ChiliPiper 
                domain={component.domain}
                inbound_router={component.inbound_router}
                session_id={conversationId || ''} 
                email={component.email}
                key={component.url}
                gettingFullResponse={gettingFullResponse || false}
            />;
        case 'youtube':
            return <YouTube video_id={component.video_id} />;
        default:
            return <FallbackComponent 
                component={component.content || component} 
                content={component} 
            />;
    }
}, () => false);

const Message: React.FC<MessageProps> = ({ 
    role, 
    content, 
    created_at, 
    leadEmail, 
    config, 
    scrollToBottom, 
    gettingResponse, 
    gettingFullResponse, 
    human_agent, 
    isVisitorTyping,
    conversationId
}) => {
    const [logo, setLogo] = useState<string>(aimdocLogo);
    const preprocessJson = (jsonString: any) => {
        // First, replace invalid \' escape sequences with just '
        let correctedString = jsonString.replace(/\\'/g, "'");
        // Then, remove trailing commas before closing braces (}) or brackets (])
        correctedString = correctedString.replace(/,(?=\s*[}\]])/g, "");
        return correctedString;
    }

    const componentMapping: ComponentMapping = {
        calendly: Calendly,
        tidycal: TidyCal,
        chili_piper: ChiliPiper,
        youtube: YouTube,
        form: Form,
        form_field: Form
    };

    const getComponent = useMemo(() => (component: Component) => {
        return (
            <MemoizedComponent
                component={component}
                config={config}
                conversationId={conversationId || ''}
                gettingFullResponse={gettingFullResponse || false}
            />
        );
    }, [config, conversationId, gettingFullResponse]);

    const detectAndRenderUrls = (content: string): string => {
        if (!gettingFullResponse) {
            return content;
        }

        const urlPattern = /(https?:\/\/[^\s]+)/g; // Simple regex for detecting URLs
        const markdownUrlPattern = /\[([^\]]+)\]\((https?:\/\/[^\s]+)\)/g; // Regex for detecting complete Markdown-style URLs
        const markdownStartPattern = /\[/; // Pattern to detect an open square bracket

        // Check if there's a potential start of a Markdown URL (an open square bracket)
        const lastOpenBracketIndex = content.lastIndexOf('[');

        // Check if there is an incomplete Markdown URL (last open bracket without a matching complete Markdown pattern)
        if (lastOpenBracketIndex !== -1) {
            const substringFromLastBracket = content.slice(lastOpenBracketIndex);
            if (!markdownUrlPattern.test(substringFromLastBracket)) {
                // If no complete Markdown URL is detected from the last open bracket, return content up to this point
                return content.slice(0, lastOpenBracketIndex);
            }
        }

        // Render normally if no incomplete Markdown URL is detected
        return content;
    };

    function hexToRgb(hex: string) {
        // Remove the hash at the start if it's there
        hex = hex.replace(/^#/, '');

        // Parse r, g, b values
        let bigint = parseInt(hex, 16);
        let r = (bigint >> 16) & 255;
        let g = (bigint >> 8) & 255;
        let b = bigint & 255;

        return `${r}, ${g}, ${b}`;
    }

    const UlRenderer = (props: any) => (
        <ul className={`pl-8 pr-8 list-disc w-full max-w-full ${gettingFullResponse ? 'break-all' : 'break-words'}`}>{props.children}</ul>
    );

    const OlRenderer = (props: any) => (
        <ol className={`pl-8 pr-8 list-decimal w-full max-w-full ${gettingFullResponse ? 'break-all' : 'break-words'}`}>{props.children}</ol>
    );

    const ListItemRenderer = (props: any) => (
        <li className={`mb-2 font-semibold w-full max-w-full ${gettingFullResponse ? 'break-all' : 'break-words'}`}>{props.children}</li>
    );

    const ParagraphRenderer = (props: any) => (
        <p className={`${role === 'assistant' ? '' : 'mb-2 w-full break-words'}`}>{props.children}</p>
    );

    const URLRenderer = (props: any) => (
        <a
            href={props.href}
            target="_blank"
            rel="noreferrer"
            className={`text-blue-500 underline break-words w-full max-w-full`}
        >
            {props.children}
        </a>
    );

    const h1Renderer = (props: any) => (
        <h1 className="text-2xl font-bold mb-2 w-full break-words">{props.children}</h1>
    );

    const h2Renderer = (props: any) => (
        <h2 className="text-xl font-bold mb-2 w-full break-words">{props.children}</h2>
    );

    const h3Renderer = (props: any) => (
        <h3 className="text-lg font-bold mb-2 w-full break-words">{props.children}</h3>
    );

    const strongRenderer = (props: any) => (
        <strong className="font-bold">{props.children}</strong>
    );

    const RenderChatComponent = (props: any) => {
        let component;

        try {
            const clean = preprocessJson(props.children);
            component = JSON.parse(clean);

            if (Array.isArray(component)) {
                return (
                    <>
                        {component.map((comp, index) => {
                            return getComponent(comp);
                        })}
                    </>
                );
            } else {
                return getComponent(component);
            }
        } catch (e) {
            // Not a long term solution but making assumption that if not valid JSON the markdown code is SQL
            // const ChatComponent = FallbackComponent;
            // return <ChatComponent component={props.children} content={content} />;
        }
    }

    const markdownRenderers = {
        ul: UlRenderer,
        ol: OlRenderer,
        li: ListItemRenderer,
        p: ParagraphRenderer,
        a: URLRenderer,
        code: RenderChatComponent,
        h1: h1Renderer,
        h2: h2Renderer,
        h3: h3Renderer
    };

    const messageBgColor = (role: string) => {
        if (config) {
            if (role === 'assistant') {
                return config ? config.lighter_color : '';
            } else {
                return config ? '' : '';
            }
        }
    };

    const checkIfMobile = () => {
        return window.innerWidth < 1025;
    };

    return (
        <div className={`flex flex-col pb-4`}>
            <div
                key={content}
                className={`flex mt-2 space-x-3 ${role === 'assistant' ? 'ml-12 justify-end' : 'mr-4'
                    }`}
            >
                {role !== 'assistant' && (
                    <div className="flex-shrink-0 items-center justify-center">
                        <div className="flex-shrink-0 items-center justify-center">
                            <div className="w-10 h-10 mr-2 flex-shrink-0 items-center justify-center rounded-full bg-blue-500 text-white flex flex-col">
                                <span className="text-lg uppercase font-semibold flex items-center justify-center">A</span>
                            </div>
                        </div>
                    </div>
                )}
                <div
                    className={`text-sm ${role === 'assistant'
                        ? `p-3 text-slate-900 dark:text-slate-100 border border-slate-200 dark:bg-slate-950 dark:border-slate-700 dark:text-slate-200 rounded-l-lg rounded-br-lg bg-opacity-50 bg-white`
                        : `${gettingResponse ? 'bg-opacity-100' : `border bg-slate-200/50 dark:bg-slate-700 dark:border-slate-600 dark:text-slate-200`} p-3 rounded-r-lg rounded-bl-lg`
                        }`}
                    style={{ backgroundColor: role === 'assistant' && config ? `rgba(${hexToRgb(config.color)}, 0.2)` : '' }}

                >
                    {isVisitorTyping ? (
                        <div className='flex space-x-2 justify-center items-center bg-transparent'>
                            <div className='h-3 w-3 shadow-lg rounded-full animate-bounce [animation-delay:-0.3s] bg-slate-500/50'></div>
                            <div className='h-3 w-3 shadow-lg rounded-full animate-bounce [animation-delay:-0.15s] bg-slate-500/50'></div>
                            <div className='h-3 w-3 shadow-lg rounded-full animate-bounce bg-slate-500/50'></div>
                        </div>
                    ) : (
                        <Markdown
                            remarkPlugins={[remarkBreaks]}
                            rehypePlugins={[rehypeRaw]}
                            children={detectAndRenderUrls(content)}
                            components={markdownRenderers}
                        ></Markdown>
                    )}
                    <div className="flex flex-row justify-between items-center">
                        {role === 'system' && (
                            <div>
                                <span className="flex rounded-lg px-2 py-1 bg-red-100/60 border border-red-200 text-red-500 text-xs font-medium dark:bg-red-200 dark:border-red-800 dark:text-red-800">System Message</span>
                            </div>
                        )}
                    </div>
                </div>
                {role === 'assistant' && !human_agent && (
                    <img
                        src={logo}
                        alt="aimdoc logo"
                        className="h-8 w-8"
                    />
                )}
                {role === 'assistant' && human_agent && (
                    <UserCircle leadEmail={human_agent.email} />
                )}
            </div>
            {created_at && (
                <span className={`mt-3 flex text-xs text-slate-400 leading-none ${role === 'assistant' ? 'justify-end mr-14' : 'justify-start ml-14'}`}>{formatDate(created_at)}</span>
            )}
        </div>
    );
};

export default Message;