import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState, useEffect } from 'react'
import useApi from '../api';
import { useParams, useNavigate } from 'react-router-dom';
import Pages from './Pages';
import { ToastContainer, toast } from 'react-toastify';
import toastConfig from '../config/Toast';
import 'react-toastify/dist/ReactToastify.css';
import { formatDate } from '../utils/dateFormatter';
import useAnalytics from '../analytics/analytics';
import { Toast } from 'react-toastify/dist/components';
import { useUser } from '../user/UserContext';

interface WebsiteProps { }

interface Website {
    id: string;
    url: string;
    base_url: string;
    title: string;
    meta_description: string;
    meta_keywords: string;
    language: string;
    created_at: string;
    updated_at: string;
    indexed: boolean;
    index_status: string;
    indexed_at: string;
}

export default function Website() {
    const { id } = useParams();
    const [modalVisible, setModalVisible] = useState(false)
    let [isOpen, setIsOpen] = useState(false)
    const { get, getStream, post, postStream, put, patch, remove } = useApi();
    const [website, setWebsite] = useState<Website | undefined>();
    const { captureEvent } = useAnalytics();
    const { checkLicenseType } = useUser();
    const [urlsToIndex, setUrlsToIndex] = useState('');

    function closeModal() {
        setModalVisible(false)
        setIsOpen(false)
    }

    function openModal() {
        setIsOpen(true)
    }

    useEffect(() => {
        if (id) {
            get(`/websites/${id}`).then((response) => {
                setWebsite(response.data);
            });
            captureEvent('viewed_website', {
                website_id: id,
            });
        }
    }, [id]);

    const handleReindex = async () => {
        try {
            const response = await post('/re-index-pages/', { website_id: id });
            toast.success('Reindexing started!', toastConfig);
            captureEvent('reindexed_pages', {
                website_id: id,
            });
        } catch (error: any) {
            toast.error(error.detail, toastConfig);
            console.error('Reindexing error:', error);
        }
    };

    const handleRecrawl = () => {
        if (!checkLicenseType('standard')) {
            toast.error('Upgrade to the Standard plan to crawl your website.', toastConfig);
            return;
        }
        try {
            post('/recrawl-website/', { website_id: id }).then((res) => {
                toast.success('Crawling in progress', toastConfig);
                captureEvent('crawl_website', {
                    website_id: website,
                });
            }).catch((error) => {
                console.error('Error crawling website:', error);
                toast.error('Error getting pages.', toastConfig);
            });
        } catch (e) {
            toast.error('Invalid website URL', toastConfig);
        }
    };

    const handleUrlSubmit = () => {
        const urls = urlsToIndex.split(/[\s,]+/).filter(url => url.trim() !== '');
        if (urls.length === 0) {
            toast.error('Please enter at least one valid URL', toastConfig);
            return;
        }

        post('/add-urls-to-index/', { website_id: id, urls: urls })
            .then((res) => {
                toast.success('URLs submitted for indexing', toastConfig);
                setUrlsToIndex('');
                captureEvent('submitted_urls_for_indexing', {
                    website_id: id,
                    url_count: urls.length,
                });
            })
            .catch((error) => {
                console.error('Error submitting URLs:', error);
                toast.error('Error submitting URLs for indexing.', toastConfig);
            });
    };

    return (
        <>
            {website && (
                <div className="flex w-full h-full overflow-y-auto scrollbar-thin justify-center text-center dark:bg-slate-900">
                    <div className="w-full transform h-full bg-white p-6 text-left align-middle dark:bg-slate-900">
                        <div
                            className="text-xl font-medium leading-6 text-slate-600 mb-6 px-4 py-2 text-center border bg-slate-100 rounded-md dark:text-slate-100 dark:bg-slate-800 dark:border-none"
                        >
                            Website Details
                        </div>
                        <button
                            type="button"
                            className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 dark:bg-slate-800 dark:text-slate-200 dark:hover:bg-slate-700 transition duration-200"
                            onClick={handleReindex}
                        >
                            Update Pages
                        </button>
                        <button
                            type="button"
                            className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 dark:bg-slate-800 dark:text-slate-200 dark:hover:bg-slate-700 transition duration-200"
                            onClick={handleRecrawl}
                        >
                            Crawl Site
                        </button>
                        <div className="flex flex-col w-full">
                            <div className="flex flex-col w-1/2">
                                <label className="block text-md font-medium leading-6 text-slate-900 pb-1 mt-6 dark:text-slate-100">
                                    URL
                                </label>
                                <div className="text-sm mt-1 dark:text-slate-300">
                                    <h1>
                                        {website.url}
                                    </h1>
                                </div>
                                <label className="block text-md font-medium leading-6 text-slate-900 pb-1 mt-6 dark:text-slate-100">
                                    Base URL
                                </label>
                                <div className="text-sm mt-1 dark:text-slate-300">
                                    <h2>
                                        {website.base_url}
                                    </h2>
                                </div>
                                <label className="block text-md font-medium leading-6 text-slate-900 pb-1 mt-6 dark:text-slate-100">
                                    Title
                                </label>
                                <div className="text-sm mt-1 dark:text-slate-300">
                                    <h3>
                                        {website.title}
                                    </h3>
                                </div>
                                <label className="block text-md font-medium leading-6 text-slate-900 pb-1 mt-6 dark:text-slate-100">
                                    Meta Description
                                </label>
                                <div className="text-sm mt-1 dark:text-slate-300">
                                    <h4>
                                        {website.meta_description}
                                    </h4>
                                </div>
                                <label className="block text-md font-medium leading-6 text-slate-900 pb-1 mt-6 dark:text-slate-100">
                                    Meta Keywords
                                </label>
                                <div className="text-sm mt-1 dark:text-slate-300">
                                    <h5>
                                        {website.meta_keywords}
                                    </h5>
                                </div>
                                <label className="block text-md font-medium leading-6 text-slate-900 pb-1 mt-6 dark:text-slate-100">
                                    Language
                                </label>
                                <div className="text-sm mt-1 dark:text-slate-300">
                                    <h6>
                                        {website.language}
                                    </h6>
                                </div>
                                <label className="block text-md font-medium leading-6 text-slate-900 pb-1 mt-6 dark:text-slate-100">
                                    Created At
                                </label>
                                <div className="text-sm mt-1 dark:text-slate-300">
                                    <h6>
                                        {formatDate(website.created_at)}
                                    </h6>
                                </div>
                                <label className="block text-md font-medium leading-6 text-slate-900 pb-1 mt-6 dark:text-slate-100">
                                    Updated At
                                </label>
                                <div className="text-sm mt-1 dark:text-slate-300">
                                    <h6>
                                        {formatDate(website.updated_at)}
                                    </h6>
                                </div>
                                <label className="block text-md font-medium leading-6 text-slate-900 pb-1 mt-6 dark:text-slate-100">
                                    Indexed
                                </label>
                                <div className="text-sm mt-1 dark:text-slate-300">
                                    <h6>
                                        {website.indexed ? 'Yes' : 'No'}
                                    </h6>
                                </div>
                                <label className="block text-md font-medium leading-6 text-slate-900 pb-1 mt-6 dark:text-slate-100">
                                    Index Status
                                </label>
                                <div className="text-sm mt-1 dark:text-slate-300">
                                    <h6>
                                        {website.index_status}
                                    </h6>
                                </div>
                                <label className="block text-md font-medium leading-6 text-slate-900 pb-1 mt-6 dark:text-slate-100">
                                    Indexed At
                                </label>
                                <div className="text-sm mt-1 dark:text-slate-300">
                                    <h6>
                                        {website.indexed_at ? formatDate(website.indexed_at) : 'N/A'}
                                    </h6>
                                </div>
                            </div>
                            <div className="flex flex-col w-1/2">
                                <label htmlFor="urlsToIndex" className="block text-md font-medium leading-6 text-slate-900 pb-1 mt-6 dark:text-slate-100">
                                    Add URLs to Index
                                </label>
                                <textarea
                                    id="urlsToIndex"
                                    value={urlsToIndex}
                                    onChange={(e) => setUrlsToIndex(e.target.value)}
                                    placeholder="Enter space or comma separated URLs to index"
                                    className="w-full h-40 mt-2 p-2 border rounded-md text-sm dark:bg-slate-800 dark:text-slate-200 dark:border-slate-600"
                                />
                                <button
                                    type="button"
                                    className="mt-4 inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 dark:bg-slate-800 dark:text-slate-200 dark:hover:bg-slate-700 transition duration-200"
                                    onClick={handleUrlSubmit}
                                >
                                    Submit URLs for Indexing
                                </button>
                            </div>
                        </div>
                        <div className="flex w-full mt-10 pb-16">
                            <div className="flex flex-col w-full space-y-6 items-center justify-center pb-16">
                                <div
                                    className="text-xl w-full font-medium leading-6 text-slate-600 px-4 py-2 text-center border bg-slate-100 rounded-md dark:text-slate-100 dark:bg-slate-800 dark:border-none"
                                >
                                    Page History
                                </div>
                                <Pages websiteId={id} />
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}