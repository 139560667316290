import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState, useEffect } from 'react'
import GoogleDriveIcon from '../icons/google_drive.png'
import { Description, Field, Fieldset, Input, Label, Legend, Select, Textarea, Button } from '@headlessui/react';
import clsx from 'clsx';
import useApi from '../api';
import { toast } from 'react-toastify';

interface CalendlyProps {
    calendlyBookingUrl: string;
    createIntegration: any;
    modalVisible: boolean;
    setModalVisible: any;
}

export default function Calendly({ calendlyBookingUrl, createIntegration, modalVisible, setModalVisible }: CalendlyProps) {
    let [isOpen, setIsOpen] = useState(false)
    const [calendlyUrl, setCalendlyUrl] = useState(calendlyBookingUrl ? calendlyBookingUrl : '');
    const { get, getStream, post, postStream, put, patch, remove } = useApi();

    function closeModal() {
        setModalVisible(false)
        setIsOpen(false)
    }

    function validateCalendlyUrl(url: string): { isValid: boolean; error?: string } {
        const trimmedUrl = url.trim();
        
        const calendlyRegex = /^https:\/\/calendly\.com\/[\w-]+(?:\/[\w-]+)?$/;
        if (!calendlyRegex.test(trimmedUrl)) {
            return { 
                isValid: false, 
                error: 'Please enter a valid Calendly URL (e.g., https://calendly.com/username/30min)'
            };
        }

        if (!trimmedUrl.split('/')[4]) {
            return { 
                isValid: false, 
                error: 'Please include a specific meeting type in your URL (e.g., https://calendly.com/username/30min)'
            };
        }

        return { isValid: true };
    }

    function createCalendlyIntegration() {
        const validation = validateCalendlyUrl(calendlyUrl);
        if (!validation.isValid) {
            toast.error(validation.error);
            return;
        }

        createIntegration({
            calendlyUrl: calendlyUrl.trim(),
            appName: 'calendly'
        });
    }

    function openModal() {
        setIsOpen(true)
    }

    useEffect(() => {
        setIsOpen(true)
    }, []);

    return (
        <>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black/25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all dark:bg-slate-800">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-medium leading-6 text-slate-900 mb-6 dark:text-slate-100"
                                    >
                                        Connect Calendly
                                    </Dialog.Title>
                                    <Field className="">
                                        <Label className="text-sm/6 font-medium text-slate-700 dark:text-white">Calendly URL</Label>
                                        <Input
                                            className={clsx(
                                                'mt-1 block w-full rounded-lg border-slate-200 dark:border-none shadow-sm dark:shadow-none bg-white dark:bg-white/20 py-1.5 px-3 text-sm/6 dark:text-white',
                                                'focus:outline-none data-[focus]:outline-2 data-[focus]:-outline-offset-2 data-[focus]:outline-white/25'
                                            )}
                                            onChange={(e) => setCalendlyUrl(e.target.value)}
                                            required={true}
                                        />
                                    </Field>  
                                    <Field className="mt-1">
                                        <div
                                            className="text-sm/6 text-slate-700 dark:text-white"
                                        >
                                            Enter your booking URL for your desired meeting type.
                                        </div>
                                    </Field>
                                    <Button
                                        onClick={() => createCalendlyIntegration()}
                                        className="mt-6 w-full items-center rounded-md bg-slate-400 dark:bg-white/10 py-1.5 px-3 text-sm/6 font-semibold text-white dark:text-white shadow-inner shadow-white/10 focus:outline-none hover:bg-slate-500 dark:data-[hover]:bg-white/20 data-[open]:bg-slate-700 data-[focus]:outline-1 data-[focus]:outline-white transition duration-200"
                                    >
                                        Connect
                                    </Button>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}
