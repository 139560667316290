import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState, useEffect } from 'react'
import { toast } from 'react-toastify';
import toastConfig from '../config/Toast';
import config from '../../config.json';

interface CopyCodeModalProps {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    agId: string;
    onCopy: (e: any, platform: string) => void;
}

export default function CopyCodeModal({ isOpen, setIsOpen, agId, onCopy }: CopyCodeModalProps) {
    function closeModal() {
        setIsOpen(false)
    }

    const embedCode = `<script>
(function() {
    function initAgent() {
        window.Agent.initAgentChat('ai-assistant', {
            agentId: '${agId}'
        });
    }
    var script = document.createElement('script');
    script.src = '${config.env === "production" ? "https://app.aimdoc.ai" : "http://localhost:3000"}/embedded.bundle.js';
    script.async = true;
    script.onload = initAgent;
    document.body.appendChild(script);
})();
</script>`;

    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={closeModal}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all dark:bg-slate-800">
                                <Dialog.Title
                                    as="h3"
                                    className="text-lg font-medium leading-6 text-slate-900 mb-6 dark:text-slate-100"
                                >
                                    Embed Your Agent
                                </Dialog.Title>
                                <div className="mt-2">
                                    <p className="text-sm text-slate-600 dark:text-slate-300">
                                        Copy and paste this code before the closing <code>&lt;/body&gt;</code> tag of your website.
                                    </p>
                                </div>

                                <div className="mt-4 bg-slate-50 dark:bg-slate-900 p-4 rounded-lg">
                                    <pre className="text-sm text-slate-800 dark:text-slate-200 overflow-x-auto">
                                        <code>{embedCode}</code>
                                    </pre>
                                </div>

                                <div className="mt-6 flex gap-4">
                                    <button
                                        type="button"
                                        className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 dark:bg-slate-700 dark:text-slate-100 dark:hover:bg-slate-600"
                                        onClick={(e) => onCopy(e, "website")}
                                    >
                                        Copy to Clipboard
                                    </button>
                                    <button
                                        type="button"
                                        className="inline-flex justify-center rounded-md border border-slate-200 bg-white px-4 py-2 text-sm font-medium text-slate-900 hover:bg-slate-50 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 dark:bg-slate-800 dark:text-slate-100 dark:border-slate-600 dark:hover:bg-slate-700"
                                        onClick={closeModal}
                                    >
                                        Close
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
} 