import React, { useState, useEffect } from 'react';
import useApi from './../api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LineChart, Line } from 'recharts';
import { useUser } from './../user/UserContext';
import { conLog } from './../utils/conLog';
import logo_symbol from '../../logo_symbol.png';


interface AnalyticsData {
    total_messages_last_7_days: number;
    total_conversations_last_7_days: number;
    messages_per_day: {
        day: string;
        message_count: number;
    }[];
    conversations_per_day: {
        day: string;
        conversation_count: number;
    }[];
    messages_by_hour: {
        hour: number;
        message_count: number;
    }[];
    average_conversation_duration_minutes: number;
}

const Analytics = () => {
    const { get } = useApi();
    const { user } = useUser();
    const [analyticsData, setAnalyticsData] = useState<AnalyticsData | null>(null);

    useEffect(() => {
        fetchAnalyticsData();
    }, []);

    const fetchAnalyticsData = async () => {
        try {
            const response = await get('/analytics/');
            conLog(response.data);
            const sortedData = {
                ...response.data,
                conversations_per_day: [...response.data.conversations_per_day].sort(
                    (a, b) => new Date(a.day).getTime() - new Date(b.day).getTime()
                )
            };
            setAnalyticsData(sortedData);
        } catch (error) {
            toast.error('Error fetching analytics data');
        }
    };

    if (!analyticsData) {
        return (
            <div className="fixed inset-0 z-50 flex justify-center items-center bg-white/50 dark:bg-slate-900/50 backdrop-blur-sm">
                <img src={logo_symbol} alt="logo" className="w-16 h-16 animate-bounce" />
            </div>
        );
    }

    const formatDate = (dateStr: string) => {
        const [year, month, day] = dateStr.split('-');
        return `${month}/${day}`;
    };

    const formatHour = (hour: number) => {
        const ampm = hour >= 12 ? 'PM' : 'AM';
        const adjustedHour = hour % 12 || 12;
        return `${adjustedHour}${ampm}`;
    };

    return (
        <div className="flex flex-col items-center w-full bg-gradient-to-br from-blue-100 to-blue-500 overflow-y-auto scrollbar-thin dark:from-slate-900 dark:to-slate-500">
            <div className="flex flex-row space-x-8 mb-8 mt-12">
                <div className="bg-white dark:bg-slate-800 rounded-lg shadow-md p-6 min-w-[200px]">
                    <p className="text-4xl font-bold text-slate-800 dark:text-slate-100">
                        {analyticsData.total_messages_last_7_days}
                    </p>
                    <p className="text-sm text-slate-500 dark:text-slate-400 mt-2">
                        Total Messages in Last 7 Days
                    </p>
                </div>
                <div className="bg-white dark:bg-slate-800 rounded-lg shadow-md p-6 min-w-[200px]">
                    <p className="text-4xl font-bold text-slate-800 dark:text-slate-100">
                        {analyticsData.total_conversations_last_7_days}
                    </p>
                    <p className="text-sm text-slate-500 dark:text-slate-400 mt-2">
                        Total Conversations in Last 7 Days
                    </p>
                </div>
                <div className="bg-white dark:bg-slate-800 rounded-lg shadow-md p-6 min-w-[200px]">
                    <p className="text-4xl font-bold text-slate-800 dark:text-slate-100">
                        {analyticsData.total_conversations_last_7_days
                            ? (analyticsData.total_messages_last_7_days / analyticsData.total_conversations_last_7_days).toFixed(1)
                            : '0'}
                    </p>
                    <p className="text-sm text-slate-500 dark:text-slate-400 mt-2">
                        Avg Messages per Conversation
                    </p>
                </div>
                <div className="bg-white dark:bg-slate-800 rounded-lg shadow-md p-6 min-w-[200px]">
                    <p className="text-4xl font-bold text-slate-800 dark:text-slate-100">
                        {analyticsData.average_conversation_duration_minutes.toFixed(1)}
                    </p>
                    <p className="text-sm text-slate-500 dark:text-slate-400 mt-2">
                        Avg Conversation Duration (mins)
                    </p>
                </div>
            </div>
            <div className="w-2/3 flex justify-center items-center space-x-8">
                <div className="flex flex-col w-full space-y-8">
                    <div className="flex flex-col w-full items-center py-4 pr-2 border rounded-lg shadow-md bg-white dark:bg-slate-800 dark:border-slate-700">
                        <div className="font-semibold text-xl text-slate-600 dark:text-slate-300 mb-2">
                            Messages Per Day
                        </div>
                        {analyticsData.messages_per_day.length === 0 ? (
                            <div className="h-[250px] flex font-medium text-slate-500 dark:text-slate-400 pt-16">No data yet!</div>
                        ) : (
                            <ResponsiveContainer width="100%" height={250}>
                                <BarChart data={analyticsData.messages_per_day}>
                                    <XAxis dataKey="day" tickFormatter={formatDate} stroke="#94a3b8" />
                                    <YAxis stroke="#94a3b8" />
                                    <Tooltip
                                        labelFormatter={formatDate}
                                        contentStyle={{ backgroundColor: 'rgb(255, 255, 255)', border: '1px solid #e2e8f0', color: '#1e293b' }}
                                    />
                                    <Bar dataKey="message_count" fill="#0f172a" />
                                </BarChart>
                            </ResponsiveContainer>
                        )}
                    </div>
                </div>
                <div className="flex flex-col w-full space-y-8">
                    <div className="flex flex-col w-full items-center py-4 pr-2 border rounded-lg shadow-md bg-white dark:bg-slate-800 dark:border-slate-700">
                        <div className="font-semibold text-xl text-slate-600 dark:text-slate-300 mb-2">
                            Conversations Per Day
                        </div>
                        {analyticsData.conversations_per_day.length === 0 ? (
                            <div className="h-[250px] flex font-medium text-slate-500 dark:text-slate-400 pt-16">No data yet!</div>
                        ) : (
                            <ResponsiveContainer width="100%" height={250}>
                                <BarChart data={analyticsData.conversations_per_day}>
                                    <XAxis dataKey="day" tickFormatter={formatDate} stroke="#94a3b8" />
                                    <YAxis stroke="#94a3b8" />
                                    <Tooltip
                                        labelFormatter={formatDate}
                                        contentStyle={{ backgroundColor: 'rgb(255, 255, 255)', border: '1px solid #e2e8f0', color: '#1e293b' }}
                                    />
                                    <Bar dataKey="conversation_count" fill="#1e3a8a" />
                                </BarChart>
                            </ResponsiveContainer>
                        )}
                    </div>
                </div>
            </div>
            <div className="flex flex-col w-2/3 space-y-8 mt-8">
                <div className="flex flex-col w-full items-center py-4 pr-2 border rounded-lg shadow-md bg-white dark:bg-slate-800 dark:border-slate-700">
                    <div className="font-semibold text-xl text-slate-600 dark:text-slate-300 mb-2">
                        Message Frequency by Hour
                    </div>
                    {analyticsData.messages_by_hour?.length === 0 ? (
                        <div className="h-[250px] flex font-medium text-slate-500 dark:text-slate-400 pt-16">No data yet!</div>
                    ) : (
                        <ResponsiveContainer width="100%" height={300}>
                            <LineChart data={analyticsData.messages_by_hour}>
                                <XAxis
                                    dataKey="hour"
                                    tickFormatter={formatHour}
                                    interval={2}
                                    stroke="#94a3b8"
                                />
                                <YAxis stroke="#94a3b8" />
                                <Tooltip
                                    labelFormatter={(hour) => `Time: ${formatHour(Number(hour))}`}
                                    formatter={(value) => [`${value} messages`, 'Count']}
                                    contentStyle={{ backgroundColor: 'rgb(255, 255, 255)', border: '1px solid #e2e8f0', color: '#1e293b' }}
                                />
                                <CartesianGrid strokeDasharray="3 3" stroke="#475569" />
                                <Line
                                    type="monotone"
                                    dataKey="message_count"
                                    stroke="#2563eb"
                                    strokeWidth={2}
                                    dot={false}
                                />
                            </LineChart>
                        </ResponsiveContainer>
                    )}
                </div>
            </div>
            {/* <div className="flex flex-col w-2/3 space-y-8 mt-8">
                <div className="flex flex-col w-full items-center py-4 pr-2 border rounded-lg shadow-md bg-white dark:bg-slate-800 dark:border-slate-700">
                    <div className="font-semibold text-xl text-slate-600 dark:text-slate-300 mb-2">
                        Message Frequency by Day
                    </div>
                    {analyticsData.messages_per_day?.length === 0 ? (
                        <div className="h-[250px] flex font-medium text-slate-500 dark:text-slate-400 pt-16">No data yet!</div>
                    ) : (
                        <ResponsiveContainer width="100%" height={300}>
                        <LineChart data={analyticsData.messages_per_day}>
                            <XAxis
                                dataKey="day"
                                tickFormatter={formatDate}
                                stroke="#94a3b8"
                            />
                            <YAxis stroke="#94a3b8" />
                            <Tooltip
                                labelFormatter={formatDate}
                                formatter={(value) => [`${value} messages`, 'Count']}
                                contentStyle={{ backgroundColor: 'rgb(255, 255, 255)', border: '1px solid #e2e8f0', color: '#1e293b' }}
                            />
                            <CartesianGrid strokeDasharray="3 3" stroke="#475569" />
                            <Line
                                type="monotone"
                                dataKey="message_count"
                                stroke="#2563eb"
                                strokeWidth={2}
                                dot={false}
                            />
                        </LineChart>
                    </ResponsiveContainer>
                    )}
                </div>
            </div> */}
        </div>
    );
};

export default Analytics;