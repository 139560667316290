import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState, useEffect } from 'react'
import clsx from 'clsx';

interface SchedulingFieldsModalProps {
    isOpen: boolean;
    closeModal: () => void;
    formFields: any[];
    onSave: (fieldMappings: Record<string, string>) => void;
    existingMappings?: Record<string, string>;
    integrationName: string;
}

export default function SchedulingFieldsModal({ 
    isOpen, 
    closeModal, 
    formFields, 
    onSave,
    existingMappings = {},
    integrationName
}: SchedulingFieldsModalProps) {
    const [fieldMappings, setFieldMappings] = useState<Record<string, string>>(existingMappings);
    const [selectedField, setSelectedField] = useState<string | null>(null);
    const [mappingValue, setMappingValue] = useState('');

    useEffect(() => {
        if (isOpen) {
            setFieldMappings(existingMappings);
        }
    }, [isOpen, existingMappings]);

    const handleFieldSelect = (fieldName: string) => {
        setSelectedField(fieldName);
        setMappingValue(fieldMappings[fieldName] || '');
    };

    const handleSaveMapping = () => {
        if (selectedField) {
            if (mappingValue.trim()) {
                setFieldMappings(prev => ({
                    ...prev,
                    [selectedField]: mappingValue.trim()
                }));
            } else {
                const newMappings = { ...fieldMappings };
                delete newMappings[selectedField];
                setFieldMappings(newMappings);
            }
            setSelectedField(null);
            setMappingValue('');
        }
    };

    const handleClearMapping = (fieldName: string, e: React.MouseEvent) => {
        e.stopPropagation();
        const newMappings = { ...fieldMappings };
        delete newMappings[fieldName];
        setFieldMappings(newMappings);
    };

    const handleCancel = () => {
        setFieldMappings(existingMappings);
        setSelectedField(null);
        setMappingValue('');
        closeModal();
    };

    const handleSaveAll = () => {
        if (Object.keys(fieldMappings).length === 0) {
            return;
        }
        onSave(fieldMappings);
        closeModal();
    };

    const isEditing = Object.keys(existingMappings).length > 0;

    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={closeModal}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all dark:bg-slate-800">
                                <Dialog.Title
                                    as="h3"
                                    className="text-lg font-medium leading-6 text-slate-900 dark:text-slate-100"
                                >
                                    Configure Action
                                </Dialog.Title>
                                <p className="mt-2 text-sm text-slate-500 dark:text-slate-400">
                                    Your AI agent will collect this information from the visitor before it is able to use this action
                                </p>
                                
                                <div className="mt-6 space-y-4">
                                    {formFields.map((field) => (
                                        <div
                                            key={field.name}
                                            className={clsx(
                                                'p-4 rounded-lg border cursor-pointer transition-all duration-200',
                                                selectedField === field.name
                                                    ? 'border-blue-500 bg-blue-50 dark:bg-blue-900/20'
                                                    : 'border-slate-200 hover:border-blue-300 dark:border-slate-700 dark:hover:border-blue-500'
                                            )}
                                            onClick={() => handleFieldSelect(field.name)}
                                        >
                                            <div className="flex items-center justify-between">
                                                <div>
                                                    <h3 className="text-md font-medium text-slate-900 dark:text-slate-100">
                                                        {field.label}
                                                    </h3>
                                                    {fieldMappings[field.name] && (
                                                        <div className="flex items-center gap-2">
                                                            <p className="text-sm text-slate-500 dark:text-slate-400 mt-1">
                                                                Mapped to: {fieldMappings[field.name]}
                                                            </p>
                                                            <button
                                                                onClick={(e) => handleClearMapping(field.name, e)}
                                                                className="text-red-500 hover:text-red-700 dark:text-red-400 dark:hover:text-red-300"
                                                            >
                                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                                                                    <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                                {selectedField && (
                                    <div className="mt-6">
                                        <label className="block text-sm font-medium text-slate-700 dark:text-slate-300">
                                            {`Enter ${integrationName} field ID`}
                                        </label>
                                        <div className="mt-2 flex gap-2">
                                            <input
                                                type="text"
                                                value={mappingValue}
                                                onChange={(e) => setMappingValue(e.target.value)}
                                                className="flex-1 rounded-md border border-slate-300 px-3 py-2 dark:bg-slate-700 dark:border-slate-600 dark:text-slate-100"
                                                placeholder="Enter field ID"
                                            />
                                            <button
                                                onClick={handleSaveMapping}
                                                className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                                            >
                                                Map Field
                                            </button>
                                        </div>
                                    </div>
                                )}

                                <div className="mt-6 flex justify-end gap-3">
                                    <button
                                        onClick={handleCancel}
                                        className="px-4 py-2 border border-slate-300 rounded-md dark:border-slate-600 dark:text-slate-300"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        onClick={handleSaveAll}
                                        disabled={Object.keys(fieldMappings).length === 0}
                                        className={clsx(
                                            "px-4 py-2 text-white rounded-md",
                                            Object.keys(fieldMappings).length === 0
                                                ? "bg-blue-400 cursor-not-allowed"
                                                : "bg-blue-600 hover:bg-blue-700"
                                        )}
                                    >
                                        {isEditing ? 'Save Action' : 'Create Action'}
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
} 