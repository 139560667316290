import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import useApi from '../api';
import ConfigureAgent from './ConfigureAgent';

export default function CreateAgent({ showModal, setShowModal }: { showModal: any, setShowModal: any }) {
    return (
        <Transition appear show={showModal} as={Fragment}>
            <Dialog as="div" className="relative z-10 h-full" onClose={() => "nothing"}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/25" />
                </Transition.Child>
                <div className="fixed inset-0 h-full">
                    <div className="flex min-h-full h-full items-center justify-center p-8 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full h-full max-w-6xl transform rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                                <div className="w-full h-full max-w-6xl">
                                    <ConfigureAgent toggleModal={() => setShowModal(false)} />
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    );
}
