import { useUser } from './UserContext'; // Adjust the path as necessary
import React from 'react';
import { useEffect, useState } from 'react';
import { OwnerInterface } from '../types';
import useApi from '../api';

const UserCircle = ({ selectedOwner, leadEmail } : { selectedOwner?: OwnerInterface, leadEmail?: any }) => {
    const [displayName, setDisplayName] = useState('');
    const { user } = useUser();

    React.useEffect(() => {
        if (selectedOwner) {
            setDisplayName(selectedOwner.name[0]);
        } else if (user && user.email ){
            setDisplayName(user.email[0]);
        }

        if (leadEmail && leadEmail[0] !== '{') {
            setDisplayName(leadEmail[0]);
        }
    }, [selectedOwner, user]);
    return (
        <div className="w-8 h-8 mr-2 flex-shrink-0 items-center justify-center rounded-full bg-blue-500 text-white flex flex-col">
            <span className="text-lg uppercase font-semibold flex items-center justify-center">{displayName}</span>
        </div>
    );
};

export default UserCircle;