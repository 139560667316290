import React, { useEffect, useState, useRef } from "react";
import useApi from '../api';
import SpinnerLocal from "../ui/SpinnerLocal";
import { toast } from "react-toastify";
import HubSpotIcon from '../icons/hubspot_logo.webp';
import OntraportIcon from '../icons/ontraport_logo.webp'
import GoogleCalendarIcon from '../icons/google_calendar.webp';
import Ontraport from './Ontraport';
import ChiliPiper from './ChiliPiper';
import Calendly from './Calendly';
import TidyCal from './TidyCal';
import ChiliPiperIcon from '../icons/chili_piper_logo.png';
import SalesforceIcon from '../icons/salesforce_logo.png';
import CalendlyIcon from '../icons/calendly_logo.png';
import TidyCalIcon from '../icons/tidycal_logo.jpeg';
import SlackIcon from '../icons/slack_logo.webp';

import { useUser } from "../user/UserContext";

interface Integration {
    id: string;
    name: string;
    description: string;
    details: any;
}

interface IntegrationParams {
    appId?: string;
    apiKey?: string;
    domainName?: string;
    inboundRouter?: string;
    calendlyUrl?: string;
    tidyCalUrl?: string;
    appName?: string;
}

const typeMap = {
    'google-calendar': 'user',
    'chili-piper': 'organization',
    'hubspot': 'organization',
    'salesforce': 'organization',
    'ontraport': 'organization',
    'calendly': 'user',
    'tidycal': 'user',
    'slack': 'organization'
}

const Integrations: React.FC = () => {
    const { get, post, remove } = useApi();
    const [integrations, setIntegrations] = useState<Integration[]>([]);
    const [fetching, setFetching] = useState<boolean>(false);
    const [creatingIntegration, setCreatingIntegration] = useState<boolean>(false);
    const [ontraportModalVisible, setOntraportModalVisible] = useState<boolean>(false);
    const [chiliPiperModalVisible, setChiliPiperModalVisible] = useState<boolean>(false);
    const [calendlyModalVisible, setCalendlyModalVisible] = useState<boolean>(false);
    const [tidyCalModalVisible, setTidyCalModalVisible] = useState<boolean>(false);
    const [checkingLicense, setCheckingLicense] = useState<boolean>(true);
    const { user, organization, checkLicenseType } = useUser();

    const queryParams = new URLSearchParams(window.location.search);
    const code = queryParams.get('code');
    const state = queryParams.get('state');
    const name = queryParams.get('name');

    const creatingIntegrationRef = useRef(creatingIntegration);
    creatingIntegrationRef.current = creatingIntegration;

    const connectGoogle = async () => {
        const response = await post('/google-drive-url/', {});
        const authUrl = response.data.auth_url;
        window.open(authUrl, '_blank');
    };

    const connectGoogleCalendar = async () => {
        const response = await post('/google-calendar-url/', {});
        const authUrl = response.data.auth_url;
        window.open(authUrl, '_blank');
    };

    const connectHubSpot = async () => {
        const response = await post('/hubspot-url/', {});
        const authUrl = response.data.auth_url;
        window.open(authUrl, '_blank');
    };

    const connectSalesforce = async () => {
        const response = await post('/salesforce-url/', {});
        const authUrl = response.data.auth_url;
        window.open(authUrl, '_blank');
    };

    const connectOntraport = async () => {
        setOntraportModalVisible(true);
    };

    const connectChiliPiper = async () => {
        setChiliPiperModalVisible(true);
    };

    const connectCalendly = async () => {
        setCalendlyModalVisible(true);
    };

    const connectTidyCal = async () => {
        setTidyCalModalVisible(true);
    };

    const connectSlack = async () => {
        const response = await post('/slack-url/', {});
        const authUrl = response.data.auth_url;
        window.open(authUrl, '_blank');
    };

    const createIntegration = async (integration: IntegrationParams) => {
        if (creatingIntegrationRef.current) return;

        const integrationType = integration.appName || name;
        if (!integrationType || !(integrationType in typeMap)) {
            toast.error('Invalid integration type');
            return;
        }

        setCreatingIntegration(true);
        try {
            await post('/integrations/', {
                code,
                state,
                name: integrationType,
                type: typeMap[integrationType as keyof typeof typeMap],
                app_id: integration.appId,
                api_key: integration.apiKey,
                domain_name: integration.domainName,
                inbound_router: integration.inboundRouter,
                calendly_url: integration.calendlyUrl,
                tidy_cal_url: integration.tidyCalUrl
            });
            if (code) {
                window.location.href = '/integrations';
            }
            toast.success('Integration created successfully', { autoClose: 2000 });
        } catch (error) {
            toast.error('Failed to create integration');
        } finally {
            setCreatingIntegration(false);
            setOntraportModalVisible(false);
            setChiliPiperModalVisible(false);
            setCalendlyModalVisible(false);
            setTidyCalModalVisible(false);
            getIntegrations();
        }
    };

    const deleteIntegration = async (id: string) => {
        try {
            await remove(`/integrations/${id}`);
            toast.success('Integration disconnected successfully', { autoClose: 2000 });
            setIntegrations(integrations.filter(integration => integration.id !== id));
        } catch (error: any) {
            const errorDetail = error?.detail;
            let errorMessage = 'Failed to delete integration';

            if (errorDetail) {
                errorMessage = typeof errorDetail === 'object' ? errorDetail.message : errorDetail;

                if (errorDetail.agents && Array.isArray(errorDetail.agents)) {
                    errorMessage += `\nAffected agents: ${errorDetail.agents.join(', ')}`;
                }
            }

            toast.error(errorMessage, { autoClose: 5000 });
        }
    };

    const getIntegrations = async () => {
        setFetching(true);
        try {
            const response = await get('/integrations/');
            setIntegrations(response.data);
        } catch (error) {
            toast.error('Failed to fetch integrations', { autoClose: 2000 });
        } finally {
            setFetching(false);
        }
    };

    useEffect(() => {
        getIntegrations();
    }, []);

    useEffect(() => {
        if (name && code && !creatingIntegrationRef.current) {
            createIntegration({
                appId: undefined,
                apiKey: undefined,
                domainName: undefined,
                inboundRouter: undefined,
                appName: name
            });
        }
    }, [name]);

    useEffect(() => {
        if (user && organization) {
            setCheckingLicense(false);
        }
    }, [user, organization]);

    const SkeletonIntegrationCard: React.FC = () => {
        return (
            <div className="flex flex-col p-3 border shadow-sm rounded-xl w-full mt-4 dark:border-slate-700 dark:bg-slate-800">
                <div className="flex flex-row justify-between">
                    <div className="pb-4 text-lg font-medium leading-6 text-gray-500 dark:text-slate-200">
                        <div className="h-7 bg-slate-200 rounded w-40 dark:bg-slate-600 animate-pulse"></div>
                    </div>
                    <div className="items-center">
                        <div className="flex-shrink-0 items-center justify-center pb-3">
                            <div className="h-10 w-10 bg-slate-200 rounded dark:bg-slate-600 animate-pulse"></div>
                        </div>
                    </div>
                </div>
                <div className="flex-1 mb-4">
                    <div className="space-y-2">
                        <div className="h-4 bg-slate-200 rounded w-full dark:bg-slate-600 animate-pulse"></div>
                        <div className="h-4 bg-slate-200 rounded w-full dark:bg-slate-600 animate-pulse"></div>
                        <div className="h-4 bg-slate-200 rounded w-full dark:bg-slate-600 animate-pulse"></div>
                        <div className="h-4 bg-slate-200 rounded w-3/4 dark:bg-slate-600 animate-pulse"></div>
                    </div>
                </div>
                <button
                    type="button"
                    className="inline-flex justify-center rounded-md border border-transparent bg-slate-100 px-4 py-2 text-sm font-medium dark:bg-slate-600"
                    disabled
                >
                    <div className="h-4 w-20 bg-slate-200 rounded dark:bg-slate-500 animate-pulse"></div>
                </button>
            </div>
        );
    };

    const renderIntegrationCard = (integrationName: string, sysName: string, integrationDescription: string, connectHandler: () => void, icon: string) => {
        const existingIntegration = integrations.find(integration => integration.name === sysName);
        const isFreeTierIntegration = sysName === 'calendly' || sysName === 'tidycal';

        if (fetching) {
            return <SkeletonIntegrationCard />
        }

        return (
            <div className="flex flex-col p-3 border shadow-sm rounded-xl w-full mt-4 dark:border-slate-700 dark:bg-slate-800">
                <div className="flex flex-row justify-between">
                    <div className="pb-4 text-lg font-medium leading-6 text-gray-500 dark:text-slate-200">
                        {integrationName}
                    </div>
                    <div className="items-center">
                        <div className="flex-shrink-0 items-center justify-center pb-3">
                            <img src={icon} alt={`${integrationName} Icon`} className="h-10" />
                        </div>
                    </div>
                </div>
                <div className="flex-1 mb-4">
                    <p className="text-sm dark:text-slate-200">{integrationDescription}</p>
                </div>
                {existingIntegration ? (
                    <button
                        type="button"
                        className="inline-flex justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 text-sm font-medium text-red-900 hover:bg-red-200 focus:outline-none"
                        onClick={() => deleteIntegration(existingIntegration.id)}
                    >
                        Disconnect
                    </button>
                ) : (
                    <button
                        type="button"
                        className="inline-flex justify-center items-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none dark:bg-slate-600 dark:text-slate-200 dark:hover:bg-slate-700 transition duration-200"
                        onClick={connectHandler}
                        disabled={checkingLicense || (!isFreeTierIntegration && !checkLicenseType('pro'))}
                    >
                        {checkingLicense ? (
                            <>
                                <svg className="animate-spin -ml-1 mr-2 h-4 w-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                                Loading...
                            </>
                        ) : !isFreeTierIntegration && !checkLicenseType('pro') ? (
                            'Upgrade to Pro'
                        ) : (
                            'Connect'
                        )}
                    </button>
                )}
                {chiliPiperModalVisible && sysName === 'chili-piper' && (
                    <ChiliPiper
                        createIntegration={createIntegration}
                        setModalVisible={setChiliPiperModalVisible}
                        modalVisible={chiliPiperModalVisible}
                        cpDomainName={existingIntegration?.details.domain_name}
                        cpInboundRouter={existingIntegration?.details.inbound_router}
                    />
                )}
                {calendlyModalVisible && sysName === 'calendly' && (
                    <Calendly
                        calendlyBookingUrl={existingIntegration?.details.calendly_url}
                        createIntegration={createIntegration}
                        setModalVisible={setCalendlyModalVisible}
                        modalVisible={calendlyModalVisible}
                    />
                )}
                {tidyCalModalVisible && sysName === 'tidycal' && (
                    <TidyCal
                        tidyCalBookingUrl={existingIntegration?.details.tidy_cal_url}
                        createIntegration={createIntegration}
                        setModalVisible={setTidyCalModalVisible}
                        modalVisible={tidyCalModalVisible}
                    />
                )}
            </div>
        );
    };

    return (
        <div className="p-8 h-full w-full dark:bg-slate-900">
            <div className="flex flex-row items-center justify-between border-b pb-4">
                <h1 className="text-2xl font-semibold text-gray-700 mt-2 dark:text-slate-200">Integrations</h1>
            </div>
            <div className="flex mt-8">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-4 w-full">
                    {renderIntegrationCard('HubSpot', 'hubspot', 'Creates an Aimdoc form in your HubSpot instance and submits lead data when a lead is created in Aimdoc. The chat transcript will be linked in HubSpot.', connectHubSpot, HubSpotIcon)}
                    {renderIntegrationCard(
                        'Salesforce',
                        'salesforce',
                        'Creates a contact in Salesforce when a lead is created in Aimdoc. The chat transcript will be linked as a Note.',
                        connectSalesforce,
                        SalesforceIcon
                    )}
                    {renderIntegrationCard('Chili Piper', 'chili-piper', 'Allows your agent to schedule meetings with customers via Chili Piper.', connectChiliPiper, ChiliPiperIcon)}
                    {renderIntegrationCard('Calendly', 'calendly', 'Allows your agent to schedule meetings with customers via Calendly.', connectCalendly, CalendlyIcon)}
                    {renderIntegrationCard('TidyCal', 'tidycal', 'Allows your agent to schedule meetings with customers via TidyCal.', connectTidyCal, TidyCalIcon)}
                    {renderIntegrationCard(
                        'Slack',
                        'slack',
                        'Send notifications about visitor interactions and leads to your Slack channels.',
                        connectSlack,
                        SlackIcon
                    )}
                    {/* {renderIntegrationCard('Google Calendar', 'google-calendar', 'Allows your agent to schedule meetings with customers.', connectGoogleCalendar, GoogleCalendarIcon)} */}
                </div>
            </div>
            {fetching && (
                <div className="w-full">
                    <SpinnerLocal />
                </div>
            )}
        </div>
    );
};

export default Integrations;