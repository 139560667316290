import React, { useState, useEffect, Fragment } from 'react';
import { toast } from 'react-toastify';
import { Dialog, Transition } from '@headlessui/react';
import useApi from '../api';
import { useUser } from '../user/UserContext';
import { useNavigate } from 'react-router-dom';
import RowDelete from '../ui/RowDelete';

interface User {
    id: string;
    email: string;
    name: string;
}

interface Organization {
    id: string;
    name: string;
    users?: User[];
    license_type: string;
    trial_end_date: string;
    settings: any;
}

const Admin = () => {
    const navigate = useNavigate();
    const [organizations, setOrganizations] = useState<Organization[]>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const { get, put, remove } = useApi();
    const { organization, setOrg, user } = useUser();
    const [selectedOrg, setSelectedOrg] = useState<Organization | null>(null);
    const [isUsersModalOpen, setIsUsersModalOpen] = useState(false);

    const getOrganizations = async () => {
        try {
            const response = await get('/organizations/');
            setOrganizations(response.data || []);
        } catch (error) {
            console.error('Error fetching organizations:', error);
            toast.error('Failed to load organizations');
        }
    };

    const handleOrgChange = async (newOrg: Organization) => {
        await put(`/users/${user?.id}/organization/${newOrg.id}`, {});
        setOrg(newOrg);
        navigate('/');
    };

    const handleDeleteOrg = async (orgId: string) => {
        try {
            await remove(`/organizations/${orgId}`);
            toast.success('Organization deleted successfully');
            getOrganizations();
        } catch (error) {
            console.error('Error deleting organization:', error);
            toast.error('Failed to delete organization');
        }
    };

    const handleDeleteUser = async (userId: string) => {
        try {
            await remove(`/users/${userId}`);
            toast.success('User deleted successfully');
            // Refresh the users list in the modal
            if (selectedOrg) {
                const updatedOrg = await get(`/organizations/${selectedOrg.id}`);
                setSelectedOrg(updatedOrg.data);
            }
        } catch (error) {
            console.error('Error deleting user:', error);
            toast.error('Failed to delete user');
        }
    };

    const showUsersModal = async (org: Organization) => {
        try {
            const response = await get(`/organizations/${org.id}`);
            setSelectedOrg(response.data);
            setIsUsersModalOpen(true);
        } catch (error) {
            console.error('Error fetching organization details:', error);
            toast.error('Failed to load organization details');
        }
    };
    
    useEffect(() => {
        getOrganizations();
    }, []);

    const filteredOrgs = organizations.filter(org =>
        org.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="px-3 mb-4">
            {/* Org Selector */}
            <div className="w-full mb-6 bg-white border border-slate-200 rounded-lg shadow-sm dark:bg-slate-800 dark:border-slate-700">
                <div className="p-2">
                    <input
                        type="text"
                        placeholder="Search organizations..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="w-full p-2 border border-slate-200 rounded-lg text-sm dark:bg-slate-700 dark:border-slate-600 dark:text-slate-200"
                    />
                </div>
                {searchTerm && (
                    <div className="max-h-48 overflow-y-auto">
                        {filteredOrgs.length > 0 ? (
                            filteredOrgs.map((org) => (
                                <button
                                    key={org.id}
                                    onClick={() => handleOrgChange(org)}
                                    className={`w-full text-left px-4 py-2 text-sm hover:bg-slate-100 dark:hover:bg-slate-700 dark:text-slate-200 ${
                                        organization?.id === org.id ? 'bg-slate-100 dark:bg-slate-700' : ''
                                    }`}
                                >
                                    {org.name}
                                </button>
                            ))
                        ) : (
                            <div className="px-4 py-2 text-sm text-slate-500 dark:text-slate-400">
                                No organizations found
                            </div>
                        )}
                    </div>
                )}
            </div>

            {/* Organizations Table */}
            <div className="w-full bg-white border border-slate-200 rounded-lg shadow-sm dark:bg-slate-800 dark:border-slate-700">
                <div className="p-4">
                    <h2 className="text-lg font-semibold mb-4 dark:text-slate-200">Organizations</h2>
                    <div className="max-h-[calc(100vh-200px)] overflow-y-auto">
                        <table className="min-w-full divide-y divide-slate-200 dark:divide-slate-700">
                            <thead className="bg-slate-50 dark:bg-slate-700">
                                <tr>
                                    <th className="px-6 py-3 text-left text-xs font-medium text-slate-500 uppercase tracking-wider dark:text-slate-200 sticky top-0 bg-slate-50 dark:bg-slate-700">Name</th>
                                    <th className="px-6 py-3 text-right text-xs font-medium text-slate-500 uppercase tracking-wider dark:text-slate-200 sticky top-0 bg-slate-50 dark:bg-slate-700">Actions</th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-slate-200 dark:bg-slate-800 dark:divide-slate-700">
                                {organizations.map((org) => (
                                    <tr key={org.id}>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-slate-900 dark:text-slate-200">
                                            {org.name}
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                            <div className="flex justify-end space-x-2">
                                                <button
                                                    onClick={() => showUsersModal(org)}
                                                    className="text-blue-600 hover:text-blue-900 dark:text-blue-400 dark:hover:text-blue-300"
                                                >
                                                    View Users
                                                </button>
                                                <RowDelete
                                                    deleteCallback={() => handleDeleteOrg(org.id)}
                                                    deleteMessage={`Are you sure you want to delete ${org.name}?`}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            {/* Users Modal */}
            <Transition appear show={isUsersModalOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={() => setIsUsersModalOpen(false)}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black/25 dark:bg-black/25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all dark:bg-slate-800">
                                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900 dark:text-slate-200">
                                        Users in {selectedOrg?.name}
                                    </Dialog.Title>
                                    <div className="mt-4">
                                        <table className="min-w-full divide-y divide-slate-200 dark:divide-slate-700">
                                            <thead>
                                                <tr>
                                                    <th className="px-6 py-3 text-left text-xs font-medium text-slate-500 uppercase tracking-wider dark:text-slate-200">Name</th>
                                                    <th className="px-6 py-3 text-left text-xs font-medium text-slate-500 uppercase tracking-wider dark:text-slate-200">Email</th>
                                                    <th className="px-6 py-3 text-right text-xs font-medium text-slate-500 uppercase tracking-wider dark:text-slate-200">Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody className="divide-y divide-slate-200 dark:divide-slate-700">
                                                {selectedOrg?.users?.map((user) => (
                                                    <tr key={user.id}>
                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-slate-900 dark:text-slate-200">{user.name}</td>
                                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-slate-900 dark:text-slate-200">{user.email}</td>
                                                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                                            <RowDelete
                                                                deleteCallback={() => handleDeleteUser(user.id)}
                                                                deleteMessage={`Are you sure you want to delete user ${user.name}?`}
                                                            />
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="mt-4">
                                        <button
                                            type="button"
                                            className="inline-flex justify-center rounded-md border border-transparent bg-slate-100 px-4 py-2 text-sm font-medium text-slate-900 hover:bg-slate-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-slate-500 focus-visible:ring-offset-2 dark:bg-slate-700 dark:text-slate-200 dark:hover:bg-slate-600"
                                            onClick={() => setIsUsersModalOpen(false)}
                                        >
                                            Close
                                        </button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </div>
    );
};

export default Admin;