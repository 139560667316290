import React, { useState, useContext, useEffect } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { Auth } from '@supabase/auth-ui-react'
import { supabase } from '../supabaseClient'
import { ThemeSupa } from '@supabase/auth-ui-shared'
import { ReactComponent as Logo } from '../../logo_main.svg';
import PrivacyPolicy from '../PrivacyPolicy';
import TermsConditions from '../TermsConditions';
import { useUser } from '../user/UserContext';
import useAnalytics from '../analytics/analytics';

function Login() {
  const navigate = useNavigate();
  const { captureEvent } = useAnalytics();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const inviteId = searchParams.get('invite_id');
    if (inviteId) {
      localStorage.setItem('pendingInviteId', inviteId);
    }

    const { data } = supabase.auth.onAuthStateChange((event, session) => {
      if (event === 'SIGNED_IN') {      
        navigate('/');
        captureEvent('logged_in', {});
      }
    });

    return () => {
      data.subscription.unsubscribe();
    };
  }, [navigate, searchParams]);

  return (

    <div className="flex justify-center w-full items-center h-screen flex-col bg-white">
      <Logo className="h-14 mr-2 mb-10"></Logo>
      <div className="w-96">
        <Auth
          supabaseClient={supabase}
          providers={['google']}
          socialLayout="horizontal"
          theme="default"
          appearance={{
            theme: ThemeSupa,
            variables: {
              default: {
                colors: {
                  brand: '#147dc8',
                  brandAccent: '#143d8f',
                  brandButtonText: 'white',
                },
              },
            },

          }}
        />
      </div>
      <div className="flex w-64 ml-1 mb-2 justify-center text-sm text-neutral-400">
        By signing in, you agree to our Privacy Policy and Terms and Conditions.
      </div>
      <div className="flex flex-row space-x-2">
        <PrivacyPolicy />
        <TermsConditions />
      </div>
    </div>
  );
}

export default Login;
