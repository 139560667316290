import React, { useState, useEffect } from 'react';
import { useUser } from './user/UserContext';
import useApi from './api';
import { ToastContainer, toast } from 'react-toastify';
import toastConfig from './config/Toast';
import { useNavigate } from 'react-router-dom';
import Logo from '../logo_symbol.png';
import { CheckCircleIcon } from '@heroicons/react/24/solid';

const AgentFromAI = ({ user, setComplete }: { user?: any, setComplete?: any }) => {
    const [step, setStep] = useState<'input' | 'processing'>('input');
    const [website, setWebsite] = useState('');
    const [currentTask, setCurrentTask] = useState(0);
    const [isTransitioning, setIsTransitioning] = useState(false);
    const [agentName, setAgentName] = useState('');
    const [subtaskIndex, setSubtaskIndex] = useState(0);
    const { put, post } = useApi();
    const { refreshUser } = useUser();
    const navigate = useNavigate();

    // Define subtasks first
    const reviewSubTasks = [
        'Reviewing',
        'Creating visitor profile',
        'Setting a goal'
    ];

    // Define tasks before using them
    const tasks = [
        'Reviewing your website',
        agentName ? `Creating ${agentName}` : 'Creating your agent',
        'Initiating training'
    ];

    const getTitle = () => {
        if (step === 'input') return "Let's build your agent";
        if (currentTask >= tasks.length) return "All set!";
        if (currentTask === 0) {
            const displayUrl = website.replace(/^https?:\/\//, '');
            return subtaskIndex === 0 
                ? `${reviewSubTasks[subtaskIndex]} ${displayUrl}`
                : reviewSubTasks[subtaskIndex];
        }
        if (currentTask === 2) return agentName ? `Training ${agentName}` : 'Training your agent';
        return agentName ? `Building ${agentName}` : 'Building your agent';
    };

    useEffect(() => {
        let interval: NodeJS.Timeout;
        if (currentTask === 0 && step === 'processing') {
            interval = setInterval(() => {
                setSubtaskIndex((prevIndex) => (prevIndex + 1) % reviewSubTasks.length);
            }, 4000);
        }
        return () => {
            if (interval) clearInterval(interval);
        };
    }, [currentTask, step]);

    const [title, setTitle] = useState(getTitle());

    const isInitialRender = React.useRef(true);

    useEffect(() => {
        if (isInitialRender.current) {
            isInitialRender.current = false;
            return;
        }

        setIsTransitioning(true);
        const timer = setTimeout(() => {
            setTitle(getTitle());
            setIsTransitioning(false);
        }, 300);
        return () => clearTimeout(timer);
    }, [step, currentTask, subtaskIndex]);

    const handleSubmit = async () => {
        let cleanedUrl = website.trim().toLowerCase();

        if (!cleanedUrl.startsWith('http://') && !cleanedUrl.startsWith('https://')) {
            cleanedUrl = 'https://' + cleanedUrl;
        }

        if (cleanedUrl.startsWith('http://')) {
            cleanedUrl = 'https://' + cleanedUrl.slice(7);
        }

        try {
            new URL(cleanedUrl);
        } catch (error) {
            // TODO: Add user-facing error message
            toast.error('Invalid URL format', toastConfig);
            console.error('Invalid URL format');
            return;
        }

        setWebsite(cleanedUrl);
        setStep('processing');

        // Task 1: Review website
        setCurrentTask(0);

        let name;
        let persona;
        let goal;
        let pop_up;
        let starters;
        try {
            const reviewResponse = await post('/agents/review-website/', {
                website: cleanedUrl
            });
            name = reviewResponse.data.name;
            persona = reviewResponse.data.persona;
            goal = reviewResponse.data.goal;
            pop_up = reviewResponse.data.pop_up;
            starters = reviewResponse.data.starters;
            setAgentName(reviewResponse.data.name);
        } catch (error) {
            console.error('Error reviewing website:', error);
            // Handle review error specifically
            return;
        }

        // Task 2: Create agent
        setCurrentTask(1);
        let agentId;
        try {
            await new Promise(resolve => setTimeout(resolve, 3000));
            const createResponse = await post('/agents/from-ai/', {
                name: name,
                persona: persona,
                goal: goal,
                pop_up: pop_up,
                starters: starters
            });
            agentId = createResponse.data.id;
        } catch (error: any) {
            console.error('Error creating agent:', error);
            toast.error(error.detail, toastConfig);
            await handleSkip();
            return;
        }

        // Task 3: Initiate training
        setCurrentTask(2);
        try {
            await post(`/agents/${agentId}/website/`, {
                url: cleanedUrl
            });
            // sleep for 2 seconds
            setCurrentTask(3);
            await new Promise(resolve => setTimeout(resolve, 2000));
        } catch (error) {
            console.error('Error initiating training:', error);
            // Handle training error
            return;
        }
        // sleep for 10 seconds for testing purposes
        // await new Promise(resolve => setTimeout(resolve, 30000));
        // setCurrentTask(3);

        // Complete walkthrough

        try {
            if (user) {
                await put(`/users/${user.id}/walkthrough-complete/`, {
                    auth_id: user.sub,
                    email: user.email,
                    username: user.nickname,
                    website: website
                });
                setComplete(true);
                refreshUser();
            } else {
                setComplete(false);
                
            }
            navigate(`/agents/${agentId}`);
        } catch (error) {
            console.error('Error completing walkthrough:', error);
            return;
        }

    };

    const handleSkip = async () => {
        if (!user) {
            setComplete(false);
            return;
        }
        try {
            await put(`/users/${user.id}/walkthrough-complete/`, {
                auth_id: user.sub,
                email: user.email,
                username: user.nickname,
                website: website
            });
            setComplete(true);
            refreshUser();
        } catch (error) {
            console.error('Error completing walkthrough:', error);
            // Handle completion error
            return;
        }
    }

    return (
        <div className="fixed inset-0 bg-white dark:bg-slate-900 z-50 flex items-center justify-center">
            <div className="absolute bottom-8 right-8">
                <button
                    onClick={handleSkip}
                    className="text-gray-500 dark:text-slate-400 text-lg underline hover:text-gray-700 dark:hover:text-slate-300"
                >
                    skip
                </button>
            </div>
            <img
                src={Logo}
                alt="Aimdoc Logo"
                className="absolute top-[20%] left-1/2 transform -translate-x-1/2 w-20 h-20"
            />            <div className="max-w-2xl w-full px-8">
                <h1
                    className={`
                    text-4xl font-bold text-blue-900 dark:text-slate-200 mb-12 text-center
                    transition-opacity duration-300 ease-in-out
                    ${isTransitioning ? 'opacity-0' : 'opacity-100'}
                `}
                >
                    {title}
                </h1>
                {step === 'input' ? (
                    <div className="space-y-6">
                        <input
                            type="url"
                            value={website}
                            onChange={(e) => setWebsite(e.target.value)}
                            placeholder="Enter your website URL"
                            className="w-full p-4 text-lg border border-slate-300 shadow-sm rounded-lg focus:ring-2 focus:ring-blue-500 
                                     dark:bg-slate-800 dark:border-slate-600 dark:text-slate-200 
                                     dark:placeholder-slate-400"
                        />
                        <button
                            onClick={handleSubmit}
                            disabled={!website}
                            className="w-full py-4 bg-blue-600 text-white rounded-lg text-lg font-semibold
                                     hover:bg-blue-700 disabled:bg-gray-300 disabled:cursor-not-allowed
                                     dark:disabled:bg-slate-700"
                        >
                            Next
                        </button>
                    </div>
                ) : (
                    <div className="space-y-6">
                        {tasks.map((task, index) => (
                            <div key={index} className="flex items-center space-x-4">
                                {index === currentTask ? (
                                    <svg width="24" height="24" className="fill-blue-900 dark:fill-slate-200" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z" opacity=".25" /><path d="M10.72,19.9a8,8,0,0,1-6.5-9.79A7.77,7.77,0,0,1,10.4,4.16a8,8,0,0,1,9.49,6.52A1.54,1.54,0,0,0,21.38,12h.13a1.37,1.37,0,0,0,1.38-1.54,11,11,0,1,0-12.7,12.39A1.54,1.54,0,0,0,12,21.34h0A1.47,1.47,0,0,0,10.72,19.9Z"><animateTransform attributeName="transform" type="rotate" dur="0.75s" values="0 12 12;360 12 12" repeatCount="indefinite" /></path></svg>
                                ) : index < currentTask ? (
                                    <CheckCircleIcon className="w-6 h-6 text-green-500 dark:text-green-400" />
                                ) : (
                                    <div className="w-6 h-6" />
                                )}
                                <span
                                    className={`
                                            text-lg
                                            ${index === currentTask ? 'text-blue-900 dark:text-slate-200 font-medium' :
                                            index < currentTask ? 'text-green-600 dark:text-green-400' :
                                                'text-gray-400 dark:text-slate-500'
                                        }
                                        `}
                                >
                                    {task}
                                </span>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default AgentFromAI;