import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useApi from '../api';
import { ToastContainer, toast } from 'react-toastify';
import toastConfig from '../config/Toast';
import 'react-toastify/dist/ReactToastify.css';
import RowDelete from '../ui/RowDelete';
import Website from '../agents/builder/Website';
import useAnalytics from '../analytics/analytics';

const WebsiteList: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [websites, setWebsites] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const navigate = useNavigate(); // Create a navigate function
  const { get, remove } = useApi();
  const { captureEvent } = useAnalytics();

  // get search params
  const search = new URLSearchParams(window.location.search);
  const newWebsite = search.get('new');

  const toggleModal = () => {
    // Logic to show modal if needed in the future
    // navigate('/websites');
  };

  useEffect(() => {
    getWebsites();
  }, []);

  const getWebsites = async () => {
    try {
      setLoading(true);
      const data = await get('/websites/');
      setWebsites(data.data);
      captureEvent('viewed_websites', {});
    } catch (error) {
      console.error('Error fetching websites:', error);
    }
    setLoading(false);
  };

  const handleDelete = async (id: string) => {
    try {
      setLoading(true);
      await remove(`/websites/${id}`);
      setWebsites(websites.filter((website: any) => website.id !== id));
      toast.success('Website deleted successfully', toastConfig);
      captureEvent('website_deleted', {
        website_id: id,
      });
    } catch (error) {
      console.error('Error deleting website:', error);
    }
    setLoading(false);
  };

  const handleSearch = async (search: string) => {
    setSearchInput(search);
    try {
      setLoading(true);
      const data = await get(`/websites/?search=${search}`);
      setWebsites(data.data);
    } catch (error) {
      console.error('Error fetching websites:', error);
    }
    setLoading(false);
  };

  const WebsitesLoadingSkeleton = () => (
    <tbody className="divide-y divide-slate-200 bg-white dark:bg-slate-700 dark:divide-slate-600">
      {[1, 2, 3].map((i) => (
        <tr key={i} className="animate-pulse">
          <td className="px-6 py-4">
            <div className="h-4 bg-slate-200 rounded w-64 dark:bg-slate-600"></div>
          </td>
          <td className="px-6 py-4">
            <div className="h-4 bg-slate-200 rounded w-48 dark:bg-slate-600"></div>
          </td>
          <td className="px-6 py-4">
            <div className="h-4 bg-slate-200 rounded w-full dark:bg-slate-600"></div>
          </td>
          <td className="px-6 py-4">
            <div className="h-4 bg-slate-200 rounded w-24 dark:bg-slate-600"></div>
          </td>
          <td className="px-6 py-4">
            <div className="h-4 bg-slate-200 rounded w-32 dark:bg-slate-600"></div>
          </td>
          <td className="px-6 py-4">
            <div className="h-4 bg-slate-200 rounded w-16 ml-auto dark:bg-slate-600"></div>
          </td>
        </tr>
      ))}
    </tbody>
  );

  return (
    <>
      <div className="p-12 overflow-y-auto max-h-[calc(100vh-1px)] scrollbar-thin grow dark:bg-slate-900">
        <div className='mx-auto rounded-md flex flex-row'>
          <div className="relative flex w-full h-12 rounded-lg bg-white border dark:bg-slate-800 dark:border-slate-700">
            <div className="grid place-items-center h-full w-12 text-slate-300 dark:border-none dark:border-slate-900">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
              </svg>
            </div>
            <input
              className="h-full w-full border-0 text-sm text-slate-700 pr-2 focus:outline-none focus:ring-1 rounded-r-lg focus:ring-blue-200 focus:border-transparent dark:bg-slate-800 dark:text-slate-300 dark:focus:ring-1 dark:focus:ring-slate-600"
              type="text"
              id="search"
              placeholder="Search websites..."
              autoComplete='off'
              value={searchInput}
              onChange={(e: any) => setSearchInput(e.target.value)}
              onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                if (e.key === 'Enter') {
                  handleSearch(e.currentTarget.value);
                }
              }}
            />
          </div>
        </div>
        <div className="flex flex-col mt-8">
          <div className="-m-1.5 overflow-x-auto pb-12">
            <div className="p-1.5 min-w-full inline-block align-middle">
              <div className="border rounded-lg shadow overflow-hidden dark:border-slate-900">
                <table className="min-w-full divide-y divide-slate-200 dark:divide-none">
                  <thead className="bg-slate-50 sticky top-0 dark:bg-slate-800">
                    <tr>
                      <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-slate-500 uppercase dark:text-slate-400">
                        URL
                      </th>
                      <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-slate-500 uppercase dark:text-slate-400">
                        Title
                      </th>
                      <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-slate-500 uppercase dark:text-slate-400">
                        Meta Description
                      </th>
                      <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-slate-500 uppercase dark:text-slate-400">
                        Index Status
                      </th>
                      <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-slate-500 uppercase dark:text-slate-400">
                        Indexed At
                      </th>
                      <th scope="col" className="flex justify-center px-6 py-3 text-end text-xs font-medium text-slate-500 uppercase dark:text-slate-400">
                        Action
                      </th>
                    </tr>
                  </thead>
                  {loading ? (
                    <WebsitesLoadingSkeleton />
                  ) : (
                    <tbody className="divide-y divide-slate-200 bg-white dark:bg-slate-700 dark:divide-slate-600">
                      {websites.length > 0 &&
                        websites.map((website: any) => (
                          <tr key={website.id}>
                            <td className="px-6 py-4 whitespace-pre-line text-sm font-semibold text-slate-800 w-1/4 hover:text-blue-500 dark:text-slate-100 dark:hover:text-slate-200">
                              <Link
                                to={`/websites/${website.id}`}
                                className="hover:underline"
                              >
                                {website.url}
                              </Link>
                            </td>
                            <td className="px-6 py-4 whitespace-pre-line text-sm text-slate-800 truncate dark:text-slate-200">
                              {website.title}
                            </td>
                            <td className="px-6 py-4 whitespace-pre-line text-sm text-slate-800 truncate dark:text-slate-200">
                              {website.meta_description}
                            </td>
                            <td className="px-6 py-4 whitespace-pre-line text-sm text-slate-800 truncate dark:text-slate-200">
                              {website.index_status}
                            </td>
                            <td className="px-6 py-4 whitespace-pre-line text-sm text-slate-800 truncate dark:text-slate-200">
                              {website.indexed_at}
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-end text-sm font-medium dark:text-slate-200">
                              <RowDelete deleteCallback={() => handleDelete(website.id)} />
                            </td>
                          </tr>
                        ))}
                      {websites.length === 0 && !loading && (
                        <tr>
                          <td colSpan={6} className="px-6 py-16 whitespace-nowrap text-sm font-medium text-slate-500 text-center grow dark:text-slate-200">
                            No websites
                          </td>
                        </tr>
                      )}
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WebsiteList;
