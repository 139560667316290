import { useState, useRef } from 'react';
import useApi from '../../api';
import { useOutsideAlerter } from '../../useOutsideAlerter';
import CreateStarter from './CreateStarter';
import { toast } from 'react-toastify';
import toastConfig from '../../config/Toast';
import useAnalytics from '../../analytics/analytics';
import { v4 as uuidv4 } from 'uuid';
import { Field, Label, Switch, Description } from '@headlessui/react'
import { useUser } from '../../user/UserContext';

const Starters = ({
    starters,
    setStarters,
    setNeedsUpdate,
    agId,
    agentName,
    enableFollowUps,
    setEnableFollowUps,
    followUpInstructions,
    setFollowUpInstructions,
    contextualStartersEnabled,
    setContextualStartersEnabled
}: {
    starters: any,
    setStarters: any,
    setNeedsUpdate: any,
    agId: string | undefined,
    agentName: string | undefined,
    enableFollowUps: boolean,
    setEnableFollowUps: any,
    followUpInstructions: string,
    setFollowUpInstructions: any,
    contextualStartersEnabled: boolean,
    setContextualStartersEnabled: any
}) => {
    const [modalVisible, setModalVisible] = useState(false);
    const [activestarter, setActivestarter] = useState<any>(null);
    const { captureEvent } = useAnalytics();
    const { checkLicenseType } = useUser();

    const toggleModal = (starter?: string) => {
        if (modalVisible) {
            setActivestarter(null);
        } else {
            setActivestarter(starter);
        }
        setModalVisible(!modalVisible);
    };

    const addStarter = (starterValue: string) => {
        if (starterValue === "") {
            toast.error('starter name is required', toastConfig);
            return false;
        }

        if (starters.length >= 3) {
            toast.error('You can only have 3 starters', toastConfig);
            return false;
        }
        const starter = {
            id: uuidv4(),
            value: starterValue,
        };
        setStarters((prevState: any) => [...prevState, starter]);
        setNeedsUpdate(true);
        captureEvent('agent_starter_added', {
            agent_id: agId,
            agent_name: agentName,
        });
        return true;
    };

    const editStarter = (id: string, starterValue: string) => {
        let updatedStarters = starters.filter((starter: any) => starter.id !== id);

        updatedStarters = [...updatedStarters, { id: id, value: starterValue }];

        setStarters(updatedStarters);
        setNeedsUpdate(true);
    };

    const deletestarter = (id: string) => {
        setStarters(starters.filter((starter: any) => starter.id !== id));
        setNeedsUpdate(true);
        captureEvent('agent_starter_deleted', {
            agent_id: agId,
            agent_name: agentName,
        });
    };

    const modalRef = useRef(null);

    useOutsideAlerter(modalRef, () => toggleModal());

    return (
        <div className='mb-6'>
            <div className="flex flex-row items-center">
                <button
                    type="button"
                    className="inline-flex justify-center rounded-md border border-transparent bg-slate-100 px-4 py-2 text-sm font-medium text-slate-600 hover:bg-slate-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 transition duration-200 dark:bg-slate-600 dark:text-slate-50 dark:hover:bg-slate-600/50"
                    onClick={() => toggleModal()}
                >
                    Add starter
                </button>
            </div>
            {starters && starters.length > 0 && (
                <div className="flex flex-col">
                    <div className="overflow-x-auto">
                        <div className="flex flex-col gap-4 mt-4">
                            {starters.length > 0 &&
                                starters.map((starter: any) => (
                                    <div className="border border-slate-200 shadow-sm rounded-lg p-4 flex flex-col leading-normal bg-white dark:bg-slate-700 dark:border-none">
                                        <div className="flex flex-row justify-between">
                                            <h2 onClick={() => toggleModal(starter)} className="text-slate-800 font-medium text-lg hover:cursor-pointer hover:text-slate-600 dark:text-slate-300 dark:font-semibold dark:hover:text-slate-300">{starter.value}</h2>
                                            <div onClick={() => deletestarter(starter.id)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 shrink-0 stroke-slate-400 hover:stroke-red-400 hover:cursor-pointer dark:stroke-slate-300">
                                                    <path strokeLinecap="round" strokeLinejoin="round" d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
            )}
            <Field className='mt-4'>
                <Label className="text-md font-medium dark:text-slate-100">{`Contextual Starters`}</Label>
                <Description className="text-sm/6 text-slate-400 dark:text-slate-300">Show AI generated starters based on the current page the visitor is on. You can modify these in your website page settings.</Description>
                <Switch
                    checked={contextualStartersEnabled}
                    onChange={setContextualStartersEnabled}
                    className={`${contextualStartersEnabled ? 'bg-green-400 dark:bg-green-700' : 'bg-slate-200 dark:bg-slate-700'
                        } relative inline-flex h-6 w-11 items-center rounded-full mt-2`}
                >
                    <span
                        className={`${contextualStartersEnabled ? 'translate-x-6' : 'translate-x-1'
                            } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                    />
                </Switch>
            </Field>
            <Field className='mt-4'>
                <Label className="text-md font-medium dark:text-slate-100">{`AI Follow-ups ${checkLicenseType('pro') ? '' : ' (Upgrade to Pro)'}`}</Label>
                <Description className="text-sm/6 text-slate-400 dark:text-slate-300">Show follow-up questions based on the conversation.</Description>
                <Switch
                    checked={enableFollowUps}
                    onChange={setEnableFollowUps}
                    disabled={!checkLicenseType('pro')}
                    className={`${enableFollowUps ? 'bg-green-400 dark:bg-green-700' : 'bg-slate-200 dark:bg-slate-700'
                        } relative inline-flex h-6 w-11 items-center rounded-full mt-2`}
                >
                    <span
                        className={`${enableFollowUps ? 'translate-x-6' : 'translate-x-1'
                            } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                    />
                </Switch>
                {enableFollowUps && (
                    <div className="mt-3">
                        <Label className="text-md font-medium dark:text-slate-100">Instructions</Label>
                        <Description className="text-sm/6 text-slate-400 dark:text-slate-300">Give the AI instructions on how to generate follow-ups.</Description>
                        <div className="flex w-1/2 mt-2 rounded-md shadow-sm ring-1 ring-inset ring-slate-300 dark:bg-slate-700 dark:ring-slate-600">
                            <textarea
                                name="welcome"
                                id="welcome"
                                autoComplete="off"
                                className="block text-sm flex-1 border-0 bg-transparent py-1.5 pl-2 text-slate-900 placeholder:text-slate-400 rounded-md focus:border-blue-300 focus:ring focus:ring-blue-200 sm:text-md focus:ring-opacity-50 sm:leading-6 dark:focus-ring-slate-500 dark:text-slate-200 dark:placeholder:text-slate-400"
                                placeholder=""
                                value={followUpInstructions || ''}
                                onChange={(e) => setFollowUpInstructions(e.target.value)}
                                maxLength={200}
                            />
                        </div>
                    </div>
                )}
            </Field>
            {modalVisible && (
                <CreateStarter
                    addStarter={addStarter}
                    editStarter={editStarter}
                    setModalVisible={setModalVisible}
                    starter={activestarter}
                />
            )}
        </div>
    );
};

export default Starters;