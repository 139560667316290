import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { PencilIcon, CheckIcon } from '@heroicons/react/24/solid';
import useApi from '../api';
import { ToastContainer, toast } from 'react-toastify';
import toastConfig from '../config/Toast';
import 'react-toastify/dist/ReactToastify.css';
import Spinner from '../documentation/Spinner';

const PageModal: React.FC<{ isOpen: boolean; closeModal: () => void; pageId: string }> = ({ isOpen, closeModal, pageId }) => {
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState<any>(null);
  const [editingStarter, setEditingStarter] = useState<string | null>(null);
  const [editedStarters, setEditedStarters] = useState<{ id: string; value: string }[]>([]);
  const [editingPopUp, setEditingPopUp] = useState(false);
  const [editedPopUp, setEditedPopUp] = useState('');
  const { get, put } = useApi();

  const popUpRef = useRef<HTMLDivElement>(null);
  const starterRefs = useRef<{ [id: string]: HTMLLIElement | null }>({});

  useEffect(() => {
    const fetchPageDetails = async () => {
      try {
        setLoading(true);
        const data = await get(`/pages/${pageId}`);
        setPage(data.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching page details:', error);
        toast.error('Error fetching page details', toastConfig);
        setLoading(false);
      }
    };

    if (isOpen) {
      fetchPageDetails();
    }

  }, [isOpen, pageId]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (editingPopUp && popUpRef.current && !popUpRef.current.contains(event.target as Node)) {
        setEditingPopUp(false);
      }
      if (editingStarter) {
        const activeStarterRef = starterRefs.current[editingStarter];
        if (activeStarterRef && !activeStarterRef.contains(event.target as Node)) {
          setEditingStarter(null);
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [editingPopUp, editingStarter]);
  const handleStarterEdit = (id: string, value: string) => {
    setEditingStarter(id);
    setEditedStarters(prev => [...prev.filter(s => s.id !== id), { id, value }]);
  };

  const handleStarterSave = async (id: string) => {
    try {
      await put(`/pages/${pageId}/starters/${id}`, { value: editedStarters.find(s => s.id === id)?.value });
      setPage((prev: any) => ({
        ...prev,
        page_config: {
          ...prev.page_config,
          starters: prev.page_config.starters.map((s: any) =>
            s.id === id ? { ...s, value: editedStarters.find(es => es.id === id)?.value || s.value } : s
          )
        }
      }));
      setEditingStarter(null);
      toast.success('Starter updated successfully', toastConfig);
    } catch (error) {
      console.error('Error updating starter:', error);
      toast.error('Error updating starter', toastConfig);
    }
  };

  const handlePopUpEdit = () => {
    setEditingPopUp(true);
    setEditedPopUp(page?.page_config?.pop_up);
  };

  const handlePopUpSave = async () => {
    try {
      await put(`/pages/${pageId}/popup/`, { pop_up: editedPopUp });
      setPage((prev: any) => ({
        ...prev,
        page_config: {
          ...prev.page_config,
          pop_up: editedPopUp
        }
      }));
      setEditingPopUp(false);
      toast.success('Pop-up message updated successfully', toastConfig);
    } catch (error) {
      console.error('Error updating pop-up message:', error);
      toast.error('Error updating pop-up message', toastConfig);
    }
  };

  const renderChunks = (chunks: any) => {
    return (
      <ul className="list-inside space-y-4 mt-4">
        {chunks.map((chunk: any, index: number) => (
          <li key={index} className="rounded-lg shadow-sm bg-slate-50 dark:bg-slate-900/30 border border-slate-200 dark:border-slate-700 p-4 mt-1 text-sm text-slate-900 dark:text-slate-100">
            <span>{chunk.text}</span>
          </li>
        ))}
      </ul>
    );
  };

  const renderAnchorTags = (anchorTags: Array<{ url: string; name: string }>) => {
    return (
      <ul className="list-inside space-y-2 mt-2">
        {anchorTags.map((tag, index) => (
          <li key={index} className="rounded-lg shadow-sm bg-slate-50 dark:bg-slate-900/30 border border-slate-200 dark:border-slate-700 p-3 text-sm text-slate-900 dark:text-slate-100">
            <a 
              href={tag.url} 
              target="_blank" 
              rel="noopener noreferrer"
              className="text-blue-600 dark:text-blue-400 hover:underline"
            >
              {tag.name}
            </a>
            <span className="block text-xs text-slate-500 dark:text-slate-400 mt-1">
              {tag.url}
            </span>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25 dark:bg-black/40" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white dark:bg-slate-800 p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title as="h3" className="text-xl text-center font-medium leading-6 text-slate-900 dark:text-white mb-4">
                  Page Details
                </Dialog.Title>
                {loading ? (
                  <div className="flex justify-center">
                    <Spinner />
                  </div>
                ) : (
                  page && (
                    <div>
                      <div className="mb-4">
                        <label className="block text-sm font-medium text-slate-700 dark:text-slate-300">URL</label>
                        <p className="mt-1 text-sm text-slate-900 dark:text-slate-100">{page.url}</p>
                      </div>
                      <div className="mb-4">
                        <label className="block text-sm font-medium text-slate-700 dark:text-slate-300">Title</label>
                        <p className="mt-1 text-sm text-slate-900 dark:text-slate-100">{page.title}</p>
                      </div>
                      <div className="mb-4">
                        <label className="block text-sm font-medium text-slate-700 dark:text-slate-300">Meta Description</label>
                        <p className="mt-1 text-sm text-slate-900 dark:text-slate-100">{page.meta_description}</p>
                      </div>
                      <div className="mb-4">
                        <label className="block text-sm font-medium text-slate-700 dark:text-slate-300">Last Indexed</label>
                        <p className="mt-1 text-sm text-slate-900 dark:text-slate-100">{page.indexed_at}</p>
                      </div>
                      <div className="mb-4">
                        <label className="block text-sm font-medium text-slate-700 dark:text-slate-300 mb-1">Pop-up Message</label>
                        <div ref={popUpRef}>
                          {editingPopUp ? (
                            <div className="flex items-center rounded-lg shadow-sm bg-slate-50 dark:bg-slate-900/30 border border-slate-200 dark:border-slate-700 p-2 mt-1 text-sm text-slate-900 dark:text-slate-100">
                              <input
                                type="text"
                                value={editedPopUp}
                                onChange={(e) => setEditedPopUp(e.target.value)}
                                className="flex-grow text-sm rounded-md border-slate-300 dark:border-slate-600 shadow-sm focus:border-slate-300 focus:ring focus:ring-slate-200 focus:ring-opacity-50 dark:bg-slate-700 dark:text-white"
                              />
                              <button
                                onClick={handlePopUpSave}
                                className="ml-2 inline-flex items-center px-2 py-1 border border-transparent text-xs font-medium rounded-md shadow-sm text-white bg-slate-600 hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500 dark:bg-blue-800 dark:hover:bg-blue-700/50 dark:border-blue-700"
                              >
                                <CheckIcon className="h-4 w-4" />
                              </button>
                            </div>
                          ) : (
                            <div className="flex items-center rounded-lg shadow-sm bg-slate-50 dark:bg-slate-900/30 border border-slate-200 dark:border-slate-700 p-2 mt-1 text-sm text-slate-900 dark:text-slate-100">
                              <p className="flex-grow text-sm text-slate-900 dark:text-slate-100">{page?.page_config?.pop_up ?? 'Re-index to automatically generate pop-up message'}</p>
                              <button
                                onClick={handlePopUpEdit}
                                className="ml-2 inline-flex items-center px-2 py-1 border border-transparent text-xs font-medium rounded-md shadow-sm text-white bg-slate-600 hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500 dark:bg-blue-800 dark:hover:bg-blue-700/50 dark:border-blue-700"
                              >
                                <PencilIcon className="h-4 w-4" />
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="mt-4">
                        <label className="block text-sm font-medium text-slate-700 dark:text-slate-300 mb-1">Starters</label>
                        {page?.page_config?.starters?.length > 0 ? (
                          <ul className="space-y-2">
                            {page?.page_config?.starters?.map((starter: { id: string; value: string }) => (
                              <li
                                key={starter.id}
                                ref={(el) => starterRefs.current[starter.id] = el}
                                className="flex items-center rounded-lg shadow-sm bg-slate-50 dark:bg-slate-900/30 border border-slate-200 dark:border-slate-700 p-2 mt-1 text-sm text-slate-900 dark:text-slate-100"
                              >
                                {editingStarter === starter.id ? (
                                  <>
                                    <input
                                      type="text"
                                      value={editedStarters.find(s => s.id === starter.id)?.value || starter.value}
                                      onChange={(e) => handleStarterEdit(starter.id, e.target.value)}
                                      className="flex-grow text-sm rounded-md border-slate-300 dark:border-slate-600 shadow-sm focus:border-slate-300 focus:ring focus:ring-slate-200 focus:ring-opacity-50 dark:bg-slate-700 dark:text-white"
                                    />
                                    <button
                                      onClick={() => handleStarterSave(starter.id)}
                                      className="ml-2 inline-flex items-center px-2 py-1 border border-transparent text-xs font-medium rounded-md shadow-sm text-white bg-slate-600 hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500 dark:bg-blue-800 dark:hover:bg-blue-700/50 dark:border-blue-700"
                                    >
                                      <CheckIcon className="h-4 w-4" />
                                    </button>
                                  </>
                                ) : (
                                  <>
                                    <span className="flex-grow text-sm text-slate-900 dark:text-slate-100">{starter.value}</span>
                                    <button
                                      onClick={() => handleStarterEdit(starter.id, starter.value)}
                                      className="ml-2 inline-flex items-center px-2 py-1 border border-transparent text-xs font-medium rounded-md shadow-sm text-white bg-slate-600 hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500 dark:bg-blue-800 dark:hover:bg-blue-700/50 dark:border-blue-700"
                                    >
                                      <PencilIcon className="h-4 w-4" />
                                    </button>
                                  </>
                                )}
                              </li>
                            ))}
                          </ul>
                        ) : (
                          <p className="mt-1 text-sm text-slate-900 dark:text-slate-100">Re-index to automatically generate starters</p>
                        )}
                      </div>
                      <div className="mt-4">
                        <label className="block text-sm font-medium text-slate-700 dark:text-slate-300">Current Page Only</label>
                        <div className="mt-1 text-sm text-slate-900 dark:text-slate-100">
                          {page.page_config?.current_page_only ? 'Yes' : 'No'}
                        </div>
                      </div>
                      {/* <div className="mt-4">
                        <label className="block text-sm font-medium text-slate-700 dark:text-slate-300">Extracted Text</label>
                        <div className="mt-1 text-sm text-slate-900 dark:text-slate-100">
                          {page && page.page_data && page.page_data.chunks && renderChunks(page.page_data.chunks)}
                        </div>
                      </div> */}
                      <div className="mt-4">
                        <label className="block text-sm font-medium text-slate-700 dark:text-slate-300">Extracted URLs</label>
                        <div className="mt-1">
                          {page?.page_data?.anchor_tags ? (
                            renderAnchorTags(page.page_data.anchor_tags)
                          ) : (
                            <p className="text-sm text-slate-900 dark:text-slate-100">No URLs extracted</p>
                          )}
                        </div>
                      </div>
                      {page.fail_message && (
                      <div className="mt-4">
                        <label className="block text-sm font-medium text-slate-700 dark:text-slate-300">Fail Message</label>
                        <div className="mt-1 text-sm text-slate-900 dark:text-slate-100">
                          {page.fail_message}
                        </div>
                      </div>
                      )}
                    </div>
                  )
                )}
                <div className="mt-4">
                  <button
                    type="button"
                    className="inline-flex justify-center rounded-md border border-transparent bg-slate-100 dark:bg-slate-700 px-4 py-2 text-sm font-medium text-slate-900 dark:text-slate-100 hover:bg-slate-200 dark:hover:bg-slate-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    onClick={closeModal}
                  >
                    Close
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default PageModal;