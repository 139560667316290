import { useState, useEffect } from 'react';
import useApi from '../../api';
import clsx from 'clsx';

interface Channel {
    id: string;
    name: string;
}

interface NotificationConfig {
    channelId: string;
    notifications: {
        newVisitor: boolean;
        newLead: boolean;
        sessionActive: boolean;
        newQuestion: boolean;
    };
}

interface SlackNotificationsProps {
    integration: any;
    value: any;
    onChange: (value: any) => void;
}

export default function SlackNotifications({ integration, value, onChange }: SlackNotificationsProps) {
    const { get } = useApi();
    const [channels, setChannels] = useState<Channel[]>([]);
    const [loading, setLoading] = useState(true);
    const [configs, setConfigs] = useState<NotificationConfig[]>(value?.configs || []);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        fetchChannels();
    }, []);

    const fetchChannels = async () => {
        try {
            const response = await get(`/slack/channels/${integration.id}`);
            setChannels(response.data);
        } catch (error) {
            console.error('Error fetching channels:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleChannelToggle = (channelId: string) => {
        const existingConfig = configs.find(c => c.channelId === channelId);
        
        if (existingConfig) {
            setConfigs(configs.filter(c => c.channelId !== channelId));
        } else {
            setConfigs([...configs, {
                channelId,
                notifications: {
                    newVisitor: true,
                    newLead: true,
                    sessionActive: true,
                    newQuestion: true
                }
            }]);
        }
    };

    const handleNotificationToggle = (channelId: string, notificationType: keyof NotificationConfig['notifications']) => {
        const newConfigs = configs.map(config => {
            if (config.channelId === channelId) {
                return {
                    ...config,
                    notifications: {
                        ...config.notifications,
                        [notificationType]: !config.notifications[notificationType]
                    }
                };
            }
            return config;
        });
        setConfigs(newConfigs);
    };

    useEffect(() => {
        onChange({ configs });
    }, [configs]);

    const filteredChannels = channels.filter(channel => 
        channel.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    if (loading) {
        return (
            <div className="animate-pulse space-y-4">
                {[1, 2, 3].map((i) => (
                    <div key={i} className="h-24 bg-slate-100 rounded-lg dark:bg-slate-700"></div>
                ))}
            </div>
        );
    }

    return (
        <div className="space-y-4">
            <div className="relative">
                <input
                    type="text"
                    placeholder="Search channels..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="w-full px-4 py-2 rounded-lg border border-slate-200 dark:border-slate-700 bg-white dark:bg-slate-800 text-slate-900 dark:text-slate-100 placeholder-slate-400 dark:placeholder-slate-500"
                />
                <div className="absolute right-3 top-1/2 transform -translate-y-1/2 text-slate-400">
                    <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                    </svg>
                </div>
            </div>

            {filteredChannels.map(channel => {
                const config = configs.find(c => c.channelId === channel.id);
                return (
                    <div key={channel.id} className="p-4 rounded-lg border border-slate-200 dark:border-slate-700 bg-white dark:bg-slate-800">
                        <div className="flex items-center justify-between mb-4">
                            <div className="flex items-center space-x-3">
                                <span className="text-slate-500 dark:text-slate-400">#</span>
                                <span className="text-lg font-medium text-slate-900 dark:text-slate-100">
                                    {channel.name}
                                </span>
                            </div>
                            <button
                                onClick={() => handleChannelToggle(channel.id)}
                                className={clsx(
                                    'px-4 py-2 rounded-md text-sm font-medium transition-colors duration-200',
                                    config 
                                        ? 'bg-red-100 text-red-800 hover:bg-red-200 dark:bg-red-900/30 dark:text-red-300 dark:hover:bg-red-900/50'
                                        : 'bg-blue-100 text-blue-800 hover:bg-blue-200 dark:bg-blue-900/30 dark:text-blue-300 dark:hover:bg-blue-900/50'
                                )}
                            >
                                {config ? 'Remove' : 'Add'}
                            </button>
                        </div>
                        {config && (
                            <div className="grid grid-cols-2 gap-4 mt-4 pt-4 border-t border-slate-200 dark:border-slate-700">
                                {Object.entries(config.notifications).map(([key, value]) => (
                                    <label key={key} className="flex items-center space-x-3">
                                        <input
                                            type="checkbox"
                                            checked={value}
                                            onChange={() => handleNotificationToggle(channel.id, key as keyof NotificationConfig['notifications'])}
                                            className="rounded border-slate-300 text-blue-600 focus:ring-blue-500 dark:border-slate-600 dark:bg-slate-700 dark:checked:bg-blue-600"
                                        />
                                        <span className="text-sm text-slate-700 dark:text-slate-300">
                                            {key === 'newVisitor' ? 'New Visitor' :
                                             key === 'newLead' ? 'New Lead' :
                                             key === 'sessionActive' ? 'Session Active' :
                                             'New Question'}
                                        </span>
                                    </label>
                                ))}
                            </div>
                        )}
                    </div>
                );
            })}
            
            {filteredChannels.length === 0 && searchQuery && (
                <div className="text-center p-8 border border-dashed rounded-lg border-slate-300 dark:border-slate-600">
                    <p className="text-slate-500 dark:text-slate-400">
                        No channels found matching "{searchQuery}"
                    </p>
                </div>
            )}
            
            {channels.length === 0 && !searchQuery && (
                <div className="text-center p-8 border border-dashed rounded-lg border-slate-300 dark:border-slate-600">
                    <p className="text-slate-500 dark:text-slate-400">
                        No channels found. Make sure you have channels in your Slack workspace.
                    </p>
                </div>
            )}
        </div>
    );
} 