import React, { useEffect, useState, useContext, useRef, Fragment } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import useApi from '../api';
import { ToastContainer, toast, ToastOptions } from 'react-toastify';
import toastConfig from '../config/Toast';
import 'react-toastify/dist/ReactToastify.css';
import { formatDate } from '../utils/dateFormatter';
import RowDelete from '../ui/RowDelete';
import Filter from '../ui/Filter';
import { FilterInterface } from '../types'
import useAnalytics from '../analytics/analytics';

interface SessionsProps {
  agentId?: string;
  leadId?: string;
  dashboard?: boolean;
}

const initialFilters: FilterInterface[] = [
  {
    id: 'is_active',
    name: 'Active',
    options: [
      { value: 'true', label: 'Yes', checked: false },
      { value: 'false', label: 'No', checked: false }
    ],
  }
];

const Sessions: React.FC<SessionsProps> = ({ agentId, leadId, dashboard }) => {
  const [loading, setLoading] = useState(true);
  const [sessions, setSessions] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState(initialFilters);
  const filtersRef = useRef<FilterInterface[]>(initialFilters);
  const [totalPages, setTotalPages] = useState(1);
  const navigate = useNavigate(); // Create a navigate function
  const location = useLocation();
  const { get, getStream, post, postStream, put, patch, remove } = useApi();
  const { captureEvent } = useAnalytics();

  // get search params
  const search = new URLSearchParams(window.location.search);
  const newAgent = search.get('new');

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const currentPage = parseInt(params.get('page') || '1');
    const currentSearch = params.get('search') || '';
    const currentFilters = initialFilters.map(filter => ({
      ...filter,
      options: filter.options.map(option => ({
        ...option,
        checked: params.get(filter.id) === option.value
      }))
    }));
    setPage(currentPage);
    setSearchInput(currentSearch);
    setFilters(currentFilters);
    filtersRef.current = currentFilters;
    getSessions(currentPage, currentSearch, currentFilters);
  }, [location.search]);

  const getSessions = async (page: number = 1, search: string = '', filters: FilterInterface[] = []) => {
    try {
      setLoading(true);
      const params = new URLSearchParams({
        page: page.toString(),
        per_page: '25',
        search,
      });
      if (agentId) {
        params.append('agent_id', agentId);
      }
      if (leadId) {
        params.append('lead_id', leadId);
      }
      if (dashboard) {
        params.set('is_active', 'true');
      }
      filters.forEach(filter => {
        filter.options.forEach(option => {
          if (option.checked) {
            params.append(filter.id, option.value);
          }
        });
      });
      const data = await get(`/sessions/?${params.toString()}`);
      setSessions(data.data.items);
      setPage(data.data.page);
      setTotalPages(data.data.pages);
      captureEvent('viewed_sessions', {});
    } catch (error) {
      console.error('Error fetching sessions:', error);
    }
    setLoading(false);
  };

  const handleNextPage = () => {
    if (page < totalPages) {
      const params = new URLSearchParams(location.search);
      params.set('page', (page + 1).toString());
      filtersRef.current.forEach(filter => {
        const selectedOption = filter.options.find(option => option.checked);
        if (selectedOption) {
          params.set(filter.id, selectedOption.value);
        }
      });
      navigate(`?${params.toString()}`);
    }
  };

  const handlePreviousPage = () => {
    if (page > 1) {
      const params = new URLSearchParams(location.search);
      params.set('page', (page - 1).toString());
      filtersRef.current.forEach(filter => {
        const selectedOption = filter.options.find(option => option.checked);
        if (selectedOption) {
          params.set(filter.id, selectedOption.value);
        }
      });
      navigate(`?${params.toString()}`);
    }
  };

  const handleDelete = async (id: string) => {

    try {
      const response = await remove(`/sessions/${id}`);
      setSessions(sessions.filter((agent: any) => agent.id !== id));
      toast.success('Session deleted successfully', toastConfig);
      captureEvent('delete_session', {
        session_id: id,
      });
    } catch (error) {
      console.error('Error deleting Session:', error);
    }
    setLoading(false);
  };

  const handleSearch = (search: string) => {
    const params = new URLSearchParams({ search, page: '1' });
    filters.forEach(filter => {
      const selectedOption = filter.options.find(option => option.checked);
      if (selectedOption) {
        params.set(filter.id, selectedOption.value);
      }
    });
    if (agentId) {
      params.set('agent_id', agentId);
    }
    if (leadId) {
      params.set('lead_id', leadId);
    }
    navigate(`?${params.toString()}`);
  };


  const handleFilterChange = (newFilters: FilterInterface[]) => {
    setFilters(newFilters);
    filtersRef.current = newFilters;
    const params = new URLSearchParams({ search: searchInput, page: '1' });
    newFilters.forEach(filter => {
      const selectedOption = filter.options.find(option => option.checked);
      if (selectedOption) {
        params.set(filter.id, selectedOption.value);
      }
    });
    if (agentId) {
      params.set('agent_id', agentId);
    }
    if (leadId) {
      params.set('lead_id', leadId);
    }
    navigate(`?${params.toString()}`);
  };

  const SessionsLoadingSkeleton = () => (
    <tbody className="divide-y divide-slate-200 bg-white dark:bg-slate-700 dark:divide-slate-600">
      {[1, 2, 3].map((i) => (
        <tr key={i} className="animate-pulse">
          <td className="px-6 py-4">
            <div className="flex justify-center">
              <div className="h-8 bg-slate-200 rounded w-40 dark:bg-slate-600"></div>
            </div>
          </td>
          <td className="px-6 py-4">
            <div className="flex justify-center">
              <div className="h-4 bg-slate-200 rounded w-32 dark:bg-slate-600"></div>
            </div>
          </td>
          <td className="px-6 py-4">
            <div className="flex justify-center">
              <div className="h-4 bg-slate-200 rounded w-32 dark:bg-slate-600"></div>
            </div>
          </td>
          <td className="px-6 py-4">
            <div className="flex justify-center">
              <div className="h-4 bg-slate-200 rounded w-40 dark:bg-slate-600"></div>
            </div>
          </td>
          {/* <td className="px-6 py-4">
            <div className="flex justify-center">
              <div className="h-4 bg-slate-200 rounded w-32 dark:bg-slate-600"></div>
            </div>
          </td> */}
          <td className="px-6 py-4">
            <div className="flex justify-center">
              <div className="h-8 bg-slate-200 rounded w-24 dark:bg-slate-600"></div>
            </div>
          </td>
          <td className="px-6 py-4">
            <div className="flex justify-center">
              <div className="h-4 bg-slate-200 rounded w-16 dark:bg-slate-600"></div>
            </div>
          </td>
        </tr>
      ))}
    </tbody>
  );

  return (
    <div className={`pt-12 flex flex-row w-full ${dashboard ? 'bg-transparent' : 'dark:bg-slate-900'}`}>
      <div className={`${dashboard ? 'hidden' : ''}`}>
        <Filter filters={filters} onFilterChange={handleFilterChange} />
      </div>
      <div className={`${dashboard ? '' : 'pr-12'} w-full grow`}>
        <div className='mx-auto rounded-md flex flex-row'>
          <div className="relative flex w-full h-12 rounded-lg bg-white border dark:bg-slate-800 dark:border-slate-700">
            <div className="grid place-items-center h-full w-12 text-slate-300 dark:border-none dark:border-slate-900">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
              </svg>
            </div>
            <input
              className="h-full w-full border-0 text-sm text-slate-700 pr-2 focus:outline-none focus:ring-1 rounded-r-lg focus:ring-blue-200 focus:border-transparent dark:bg-slate-800 dark:text-slate-300 dark:focus:ring-1 dark:focus:ring-slate-600"
              type="text"
              id="search"
              placeholder="Search sessions by lead or message..."
              autoComplete='off'
              value={searchInput}
              onChange={(e: any) => setSearchInput(e.target.value)}
              onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                if (e.key === 'Enter') {
                  handleSearch(e.currentTarget.value);
                }
              }}
            />

          </div>
        </div>
        <div className="flex flex-col mt-8 max-h-[calc(100vh-220px)]">
          <div className="-m-1.5 overflow-x-auto scrollbar-thin">
            <div className="p-1.5 min-w-full inline-block align-middle">
              <div className="border rounded-lg shadow overflow-hidden dark:border-slate-900">
                <table className="min-w-full divide-y divide-slate-200 dark:divide-none">
                  <thead className="bg-slate-50 sticky top-0 dark:bg-slate-800">
                    <tr>
                    <th scope="col" className="text-center items-center px-6 py-3 text-xs font-medium text-slate-500 uppercase dark:text-slate-400">
                      </th>
          
                      <th scope="col" className="text-center items-center px-6 py-3 text-xs font-medium text-slate-500 uppercase dark:text-slate-400">
                        Session Started
                      </th>
                      <th scope="col" className="text-center items-center px-6 py-3 text-xs font-medium text-slate-500 uppercase dark:text-slate-400">
                        Last Active
                      </th>
                      <th scope="col" className="text-center items-center px-6 py-3 text-xs font-medium text-slate-500 uppercase dark:text-slate-400">
                        Lead
                      </th>
                      {/* <th scope="col" className="text-center items-center px-6 py-3 text-xs font-medium text-slate-500 uppercase dark:text-slate-400">
                        Agent
                      </th> */}
                      <th scope="col" className="text-center items-center px-6 py-3 text-xs font-medium text-slate-500 uppercase dark:text-slate-400">
                        Status
                      </th>
                      <th scope="col" className="text-center items-center px-6 py-3 text-xs font-medium text-slate-500 uppercase dark:text-slate-400">
                        Delete
                      </th>
                    </tr>
                  </thead>
                  {loading ? (
                    <SessionsLoadingSkeleton />
                  ) : (
                    <tbody className="divide-y divide-slate-200 bg-white dark:bg-slate-700 dark:divide-slate-600">
                      {sessions.length > 0 && !loading &&
                        sessions.map((session: any) => (
                          <tr key={session.id} className="">
                            <td className="px-6 py-4 whitespace-pre-line text-sm font-semibold text-slate-800 w-1/6 hover:text-blue-500 dark:text-slate-100 dark:hover:text-slate-200">
                                <Link
                                  to={`/sessions/${session.id}`}
                                  className="flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 dark:bg-slate-600 dark:text-slate-200 dark:hover:bg-slate-800/60 transition duration-200"
                                >
                                  View
                                </Link>
                            </td>
                            <td className="px-6 py-4 whitespace-pre-line text-xs font-light text-slate-800 w-1/6 hover:text-blue-500 dark:text-slate-100 dark:hover:text-slate-200">
                              <div className="flex flex-row justify-center">
                              {formatDate(session.created_at)}
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-pre-line text-xs font-light text-slate-800 w-1/6 hover:text-blue-500 dark:text-slate-100 dark:hover:text-slate-200">
                              <div className="flex flex-row justify-center">
                              {formatDate(session.last_activity)}
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-pre-line text-sm text-slate-800 truncate hover:text-blue-500 w-1/6 dark:text-slate-100 dark:hover:text-slate-200">
                              <div className="flex flex-row justify-center">
                                {session.lead && (
                                  <Link
                                    to={`/leads/${session.lead.id}`}
                                    className="hover:underline"
                                  >
                                    {session.lead.email}
                                  </Link>
                                )}
                              </div>
                            </td>
                            {/* <td className="items-center px-6 py-4 whitespace-pre-line text-sm text-slate-800 truncate hover:text-blue-500 w-1/6 dark:text-slate-100 dark:hover:text-slate-200">
                              <div className="flex flex-row justify-center">
                                {session.agent ? (
                                  <Link
                                    to={`/agents/${session.agent.id}`}
                                    className="hover:underline"
                                  >
                                    {session.agent.name}
                                  </Link>
                                ) : (
                                  '[deleted]'
                                )}
                              </div>
                            </td> */}
                            <td className="items-center px-6 py-4 whitespace-pre-line text-slate-800 dark:text-slate-200">
                              <div className="flex flex-row justify-center">
                                {session.status === 'active' ? (
                                  <div className="flex flex-row items-center space-x-1 bg-green-100 border-green-200 border animate-pulse text-green-800 text-sm rounded-lg py-1 px-2 text-xs dark:bg-green-700 dark:border-green-600 dark:text-green-200">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                      <path strokeLinecap="round" strokeLinejoin="round" d="m3.75 13.5 10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75Z" />
                                    </svg>

                                    <div className="font-semibold">
                                      Active
                                    </div>
                                  </div>
                                ) : (
                                  <div className="flex flex-row items-center space-x-1 bg-slate-100 border-slate-200 border text-slate-500 text-sm rounded-lg py-1 px-2 text-xs dark:bg-slate-800 dark:border-slate-600 dark:text-slate-400">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                                      <path strokeLinecap="round" strokeLinejoin="round" d="M11.412 15.655 9.75 21.75l3.745-4.012M9.257 13.5H3.75l2.659-2.849m2.048-2.194L14.25 2.25 12 10.5h8.25l-4.707 5.043M8.457 8.457 3 3m5.457 5.457 7.086 7.086m0 0L21 21" />
                                    </svg>
                                    <div className="font-semibold">
                                      Inactive
                                    </div>
                                  </div>
                                )}
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-end text-sm font-medium">
                              <RowDelete deleteCallback={() => handleDelete(session.id)} />
                            </td>
                          </tr>
                        ))}
                      {sessions.length === 0 && !loading && (
                        <tr>
                          <td colSpan={6} className="px-6 py-16 whitespace-nowrap text-sm font-medium text-slate-500 text-center grow dark:text-slate-200">
                            No sessions
                          </td>
                        </tr>
                      )}
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-between items-center mt-4">
          <button
            onClick={handlePreviousPage}
            disabled={page === 1}
            className="px-4 py-2 bg-slate-200 text-slate-700 rounded hover:bg-slate-300 font-semibold dark:bg-slate-700 dark:text-slate-200 dark:hover:bg-slate-800"
          >
            Previous
          </button>
          <span className="text-sm font-semibold text-slate-600 dark:text-slate-300">
            Page {page} of {totalPages}
          </span>
          <button
            onClick={handleNextPage}
            disabled={page === totalPages}
            className="px-4 py-2 bg-slate-200 text-slate-700 rounded hover:bg-slate-300 font-semibold dark:bg-slate-700 dark:text-slate-200 dark:hover:bg-slate-800"
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default Sessions;