import React from 'react';

const YouTubeVideo = React.memo(({ video_id }: { video_id: string}) => {
  const videoSrc = `https://www.youtube.com/embed/${video_id}?si=A1-hg4lAVUiyAxrX`;

  const checkIfMobile = () => {
    return window.innerWidth < 768;
  };

  return (
    <div className="flex w-full justify-center mt-8 mb-8">
      <iframe
        className="rounded-lg shadow-lg"
        width={`${checkIfMobile() ? '300' : '799'}`}
        height={`${checkIfMobile() ? '200' : '450'}`}
        src={videoSrc}
        title="YouTube video player"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      ></iframe>
    </div>
  );
});

export default YouTubeVideo;
