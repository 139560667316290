import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useState, useEffect } from 'react'
import useApi from '../../api';

interface starterProps {
    addField: any;
    editField: any;
    setModalVisible: any;
    field: any;
}

interface InputType {
    value: string;
    displayName: string;
}

const supportedInputTypes: InputType[] = [
    { value: 'text', displayName: 'Text' },
    { value: 'email', displayName: 'Email' },
    { value: 'tel', displayName: 'Telephone' },
    { value: 'number', displayName: 'Number' },
    { value: 'url', displayName: 'URL' },
    { value: 'date', displayName: 'Date' },
    { value: 'dropdown', displayName: 'Dropdown' }
];

export default function CreateField({ addField, editField, setModalVisible, field }: starterProps) {
    let [isOpen, setIsOpen] = useState(false)
    const [fieldLabel, setFieldLabel] = useState(field ? field.label : '');
    const [fieldName, setFieldName] = useState(field ? field.name : '');
    const [fieldType, setFieldType] = useState(field ? field.type : 'text');
    const [fieldRequired, setFieldRequired] = useState(field ? field.required : false);
    const [fieldOptions, setFieldOptions] = useState<string[]>(field?.options || []);
    const [newOption, setNewOption] = useState('');

    function closeModal() {
        setModalVisible(false)
        setIsOpen(false)
    }

    function validateField() {
        if (!fieldLabel.trim()) {
            return { valid: false, error: "Field label is required" };
        }

        if (fieldType === 'dropdown' && fieldOptions.length < 1) {
            return { valid: false, error: "Dropdown fields require at least one option" };
        }

        return { valid: true, error: null };
    }

    function updateField() {
        const validation = validateField();
        if (!validation.valid) {
            alert(validation.error);
            return;
        }

        const updated = editField({
            label: fieldLabel,
            type: fieldType,
            required: fieldRequired,
            name: fieldName,
            options: fieldType === 'dropdown' ? fieldOptions : undefined
        });

        if (updated) {
            closeModal();
            setModalVisible(false);
        }
    }

    function addNewField() {
        const validation = validateField();
        if (!validation.valid) {
            alert(validation.error);
            return;
        }

        const newFieldName = fieldLabel.toLowerCase().replace(/\s+/g, '_');
        setFieldName(newFieldName);
        const added = addField({
            label: fieldLabel,
            type: fieldType,
            required: fieldRequired,
            name: newFieldName,
            options: fieldType === 'dropdown' ? fieldOptions : undefined
        });
        
        if (added) {
            closeModal();
            setModalVisible(false);
        }
    }

    const addOption = () => {
        if (newOption.trim() && !fieldOptions.includes(newOption.trim())) {
            setFieldOptions([...fieldOptions, newOption.trim()]);
            setNewOption('');
        }
    };

    const removeOption = (optionToRemove: string) => {
        setFieldOptions(fieldOptions.filter(option => option !== optionToRemove));
    };

    useEffect(() => {
        setIsOpen(true)
    }, []);

    return (
        <>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black/25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all dark:bg-slate-800">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-lg font-medium leading-6 text--900 mb-6 dark:text-slate-100"
                                    >
                                        Add Form Field
                                    </Dialog.Title>
                                    <label className="block text-sm font-medium leading-6 text--900 pb-1 mt-6 dark:text-slate-200">
                                        Label
                                    </label>
                                    <div className="mt-3">
                                        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring--300 dark:bg-slate-700 dark:ring-0">
                                            <textarea
                                                name="starter"
                                                id="starter"
                                                autoComplete="off"
                                                className="block flex-1 border-0 bg-transparent py-1.5 pl-2 text--900 placeholder:text--400 rounded-md focus:border-blue-300 focus:ring focus:ring-blue-200 sm:text-md focus:ring-opacity-50 sm:leading-6 dark:text-slate-300"
                                                placeholder=""
                                                autoFocus={true}
                                                value={fieldLabel || ''}
                                                onChange={(e) => setFieldLabel(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <label className="block text-sm font-medium leading-6 text--900 pb-1 mt-6 dark:text-slate-200">
                                        Type
                                    </label>
                                    <div className="mt-3">
                                        <div className="flex rounded-md shadow-sm ring-1 ring-inset ring--300 dark:bg-slate-700 dark:ring-0">
                                            <select
                                                name="fieldType"
                                                id="fieldType"
                                                className="block flex-1 border-0 bg-transparent py-1.5 pl-2 text--900 placeholder:text--400 rounded-md focus:border-blue-300 focus:ring focus:ring-blue-200 sm:text-md focus:ring-opacity-50 sm:leading-6 dark:text-slate-300"
                                                value={fieldType}
                                                onChange={(e) => setFieldType(e.target.value)}
                                            >
                                                {supportedInputTypes.map((type) => (
                                                    <option key={type.value} value={type.value}>
                                                        {type.displayName}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>            
                                    <label className="block text-sm font-medium leading-6 text--900 pb-1 mt-6 dark:text-slate-200">
                                        Required
                                    </label>
                                    <div className="mt-3">
                                        <div className="flex items-center">
                                            <input
                                                type="checkbox"
                                                id="fieldRequired"
                                                name="fieldRequired"
                                                checked={fieldRequired}
                                                onChange={(e) => setFieldRequired(e.target.checked)}
                                                className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                                            />
                                            <label htmlFor="fieldRequired" className="ml-2 block text-sm text-gray-900 dark:text-slate-300">
                                                This field is required
                                            </label>
                                        </div>
                                    </div>                                                    
                                    {fieldType === 'dropdown' && (
                                        <div className="mt-6">
                                            <label className="block text-sm font-medium leading-6 text-gray-900 pb-1 dark:text-slate-200">
                                                Dropdown Options
                                            </label>
                                            <div className="mt-3 flex gap-2">
                                                <div className="flex-1 rounded-md shadow-sm ring-1 ring-inset ring-gray-300 dark:bg-slate-700 dark:ring-0">
                                                    <input
                                                        type="text"
                                                        value={newOption}
                                                        onChange={(e) => setNewOption(e.target.value)}
                                                        className="block w-full border-0 bg-transparent py-1.5 pl-2 text-gray-900 placeholder:text-gray-400 rounded-md focus:border-blue-300 focus:ring focus:ring-blue-200 sm:text-sm focus:ring-opacity-50 dark:text-slate-300"
                                                        placeholder="Add new option"
                                                    />
                                                </div>
                                                <button
                                                    type="button"
                                                    onClick={addOption}
                                                    className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 dark:bg-slate-600 dark:text-slate-100 dark:hover:bg-slate-700"
                                                >
                                                    Add
                                                </button>
                                            </div>
                                            {fieldOptions.length > 0 && (
                                                <div className="mt-3">
                                                    <ul className="space-y-2">
                                                        {fieldOptions.map((option, index) => (
                                                            <li key={index} className="flex items-center justify-between bg-gray-50 p-2 rounded-md dark:bg-slate-700">
                                                                <span className="dark:text-slate-300">{option}</span>
                                                                <button
                                                                    onClick={() => removeOption(option)}
                                                                    className="text-red-600 hover:text-red-800 dark:text-red-400 dark:hover:text-red-300"
                                                                >
                                                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                                                        <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                                                                    </svg>
                                                                </button>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    <div className="mt-4">
                                        {field ? (
                                            <button
                                            type="button"
                                            className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 dark:bg-slate-600 dark:text-slate-100 hover:dark:bg-slate-700"
                                            onClick={() => updateField()}
                                        >
                                            Save field
                                        </button>  
                                        ) : (
                                            <button
                                            type="button"
                                            className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                            onClick={() => addNewField()}
                                        >
                                            Create field
                                        </button>
                                        )}
                   
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}
