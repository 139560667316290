import { useUser } from './UserContext'; // Adjust the path as necessary
import React from 'react';
import { useEffect, useState } from 'react';
import { OwnerInterface } from '../types';
import useApi from '../api';
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import toastConfig from '../config/Toast';

const DeleteOrganization = () => {
    const [displayName, setDisplayName] = useState('');
    const { user } = useUser();
    const { get, getStream, post, postStream, put, patch, remove } = useApi();
    // get user from url params
    const { id } = useParams();
    const [deletedOrganization, setDeletedOrganization] = useState(false);

    useEffect(() => {
        const deleteOrganization = async () => {
            try {
                const response = await remove(`/organizations/${id}`);
                setDeletedOrganization(true);
                toast.success('Organization deleted successfully', toastConfig);
            } catch (error) {
                console.error('Error deleting organization:', error);
                toast.error('Error deleting organization', toastConfig);
            }
        };
        if (id) {
            deleteOrganization();
        }
    }, [id]);


    return (
        <div className="w-10 h-10 mr-2 flex-shrink-0 items-center justify-center rounded-full bg-blue-500 text-white flex flex-col">
            <span className="text-lg uppercase font-semibold flex items-center justify-center">{deletedOrganization}</span>
        </div>
    );
};

export default DeleteOrganization;